import { FieldType } from "./client";
import { ListEntity } from "./states";

export enum TicketCreationScope {
  Members = "members",
}

export enum TicketType {
  Issue = "issue",
  Communication = "communication",
}

export enum ObjectType {
  Ticket = "ticket",
  Announcement = "announcement",
  Indicator = "indicator",
  Routine = "routineinstance",
  RoutineStep = "routinestep",
}

export enum MediaType {
  File = "file",
  Url = "url",
}

export enum DependencyType {
  Related = "related",
  IsResult = "is_result",
  Resulted = "resulted",
  IsImpacted = "is_impacted",
  Contains = "contains",
  IsContained = "is_contained",
  Blocks = "blocks",
  IsBlocked = "is_blocked",
}

export interface TicketConfig {
  name?: string;
}

export interface Task extends ListEntity {
  uuid?: string;
  ticket?: number | string | null;
  closed_at?: string;
  created_at: string;
  description?: string;
  done: boolean;
  due_date?: string | null;
  num_mentions: number;
  occurrence: number;
  order: number;
  step_process: any;
  user?: number;
  created_from_template?: boolean;
  config?: Record<string, any>;
}

export interface Dependency extends ListEntity {
  dependency_type?: DependencyType;
  dependent_id?: number;
  dependent_model?: ObjectType;
  dependent_subelement_id?: number | null;
  dependent_subelement_model?: ObjectType | null;
  related_id?: number;
  related_model: ObjectType;
  related_subelement_id?: number | null;
  related_subelement_model?: ObjectType | null;
  created_at?: string;
}

export interface File {
  url?: string;
}

export interface FabriqFile extends ListEntity {
  uuid?: string;
  comment?: number | null | string;
  ticket?: number | null | string;
  answer?: number | null | string;
  step?: number | null | string;
  filename?: string;
  deleted?: string | null;
  deleted_by?: number | null;
  created_at: string;
  last_modified_by: number;
  media_type: MediaType;
  url?: string;
  blob?: Blob;
  _file: File;
}

export interface Process {
  id: number;
  name: string;
  ticket_workflow_id: number;
}

export interface TicketFields {
  zone?: number[];
  reference?: number[];
  equipment?: number[];
  priority?: number[];
  product?: number[];
}

export interface TicketField extends ListEntity {
  fieldId: number;
  ticketId: number;
  type: FieldType;
}

export interface Ticket extends ListEntity {
  uuid?: string;
  title: string;
  cause?: string | null;
  closed_at?: string;
  comments_count?: number;
  config?: TicketConfig | null;
  created_at: string;
  created_by?: number;
  categories?: number[];
  dependencies?: Array<Dependency>;
  extra_fields?: TicketFields | null;
  description?: string;
  due_date?: string;
  due_time?: string | null;
  end_date?: string | null;
  end_time?: string | null;
  files?: Array<FabriqFile>;
  followers?: number[];
  indicators?: number[];
  is_open?: boolean;
  labels?: number[];
  occurence?: number;
  owner: number | null;
  processes?: Array<Process>;
  processes_active?: boolean;
  ticket_processes?: Array<Process>;
  ticket_template?: number;
  ticket_template_id?: number | null;
  generic_file_ids?: number[];
  status?: number;
  tasks?: Array<Task>;
  teams: number[];
  _type: TicketType;
}

export interface CommentReaction {
  comment: number;
  reaction: string;
  user: number;
}

export interface Comment extends ListEntity {
  uuid?: string;
  ticket?: number | string;
  instance?: number | string;
  author: number;
  reactions: Array<CommentReaction>;
  files: Array<FabriqFile>;
  reply_author_ids: number[];
  num_replies: number;
  last_replied_at: string | null;
  created_at: string;
  content: string;
  mentioned_user_ids: number[];
  tagged_children: number[];
  is_edited: boolean;
  pinned: boolean;
  instance_type: ObjectType;
  object_id: number;
  pinned_by: number | null;
  deleted_by: number | null;
  parent: number | null;
}

export interface TicketTemplateConfig {
  emoji?: string;
  i18n?: { [key: string]: string };
}

export enum TicketTemplateFieldType {
  Column = "column",
  Field = "field",
}

export enum TicketColumn {
  Title = "title",
  Owner = "owner",
  Due_date = "due_date",
  Teams = "teams",
  Files = "files",
  Categories = "categories",
  Status = "status",
  Labels = "labels",
  Description = "description",
  Cause = "cause",
}

export interface TranslationsJSON {
  en: string;
  fr?: string;
  es?: string;
  cs?: string;
  de?: string;
  it?: string;
  ja?: string;
  ko?: string;
  pl?: string;
  pt?: string;
  ro?: string;
  sl?: string;
  tr?: string;
  zh?: string;
}

export interface TicketTemplateField {
  type: TicketTemplateFieldType;
  field_type: FieldType;
  label: TicketColumn;
  name: TranslationsJSON;
  placeholder: TranslationsJSON;
  help: TranslationsJSON;
  value: any;
  required: boolean;
  readonly: boolean;
  forbidden: boolean;
  order: number;
}

export interface TicketTemplateTask {
  description: string;
  owner_id: number | null;
  resolution_time: number | null;
  order: number;
  config?: Record<string, any>;
}

export interface TicketTemplate extends ListEntity {
  label: string;
  description: string;
  ticket_template_id: number | null;
  master_ticket_template_id: number | null;
  latest: boolean;
  hideTasks: boolean;
  hideDependencies: boolean;
  hideFiles: boolean;
  hideComments: boolean;
  hideProcesses: boolean;
  alertOnNudge: boolean;
  process_id: number | null;
  config: TicketTemplateConfig;
  fields: TicketTemplateField[];
  tasks: TicketTemplateTask[];
  updated_at: string;
  deleted_at: string;
  pre_selected_teams: number[];
}
