<template>
  <ion-content class="ion-padding-top normal">
    <ion-list lines="none" class="ion-margin-top">
      <ion-item @click="$emit('done', 'copy')" class="clickable">
        <font-icon slot="start" name="content_copy" material />
        <ion-label class="list-item">
          {{ t("tickets.fileLink") }}
        </ion-label>
      </ion-item>
      <ion-item @click="$emit('done', 'delete')" class="clickable">
        <font-icon
          slot="start"
          name="delete"
          color="var(--ion-color-danger)"
          material
        />
        <ion-label class="list-item" color="danger">
          {{ t("tickets.deleteFile") }}
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { IonContent, IonList, IonItem, IonLabel } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";

export default defineComponent({
  name: "FileActionsModal",
  components: {
    IonContent,
    IonList,
    IonItem,
    IonLabel,
  },
  emits: ["done", "cancel"],
  setup() {
    return {
      ...useI18n(),
    };
  },
});
</script>

<style scoped>
.list-item {
  font-size: var(--font-size-m);
}
</style>
