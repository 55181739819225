import {
  TemplateStep,
  Template,
  RuleCondition,
  ExecutionAnswer,
} from "@/types";

const checkValues = (a: any, b: any) => {
  if (a === null || a === undefined) return false;
  if (b === null || b === undefined) return false;
  return true;
};

const defaultOperatorOptions = [
  {
    operator: "<",
    display: "<",
    func: (a: any, b: any) => {
      if (!checkValues(a, b)) return false;
      return a < b;
    },
  },
  {
    operator: "<=",
    display: "≤",
    func: (a: any, b: any) => {
      if (!checkValues(a, b)) return false;
      return a <= b;
    },
  },
  {
    operator: "=",
    display: "=",
    func: (a: any, b: any) => {
      if (!checkValues(a, b)) return false;
      return a == b;
    },
  },
  {
    operator: "!=",
    display: "≠",
    func: (a: any, b: any) => {
      if (!checkValues(a, b)) return false;
      return a != b;
    },
  },
  {
    operator: ">=",
    display: "≥",
    func: (a: any, b: any) => {
      if (!checkValues(a, b)) return false;
      return a >= b;
    },
  },
  {
    operator: ">",
    display: ">",
    func: (a: any, b: any) => {
      if (!checkValues(a, b)) return false;
      return a > b;
    },
  },
];

const additionalOperatorOptions = [
  {
    operator: "∋",
    display: "∋",
    func: (a: any, b: any) => {
      if (Array.isArray(b))
        return b?.every((element) => a?.includes(`${element}`)) ?? false;
      return a?.includes(`${b}`) ?? false;
    },
  },
  {
    operator: "∌",
    display: "∌",
    func: (a: any, b: any) => !a?.includes(`${b}`) ?? false,
  },
  {
    operator: "⊢",
    display: "⊢",
    func: (a: any, b: any) => a?.startsWith(b) ?? false,
  },
  {
    operator: "⊣",
    display: "⊣",
    func: (a: any, b: any) => a?.endsWith(b) ?? false,
  },
];

const allOperators = [...defaultOperatorOptions, ...additionalOperatorOptions];

// TODO be careful if we add hours in step date => we'll have to use date-fns

export const getCheckResult = (
  conditionOperator: string,
  value: any,
  answerValue: any
) => {
  const operator = allOperators.find((c) => c.operator === conditionOperator);
  if (!operator) return false;
  return operator.func(value, answerValue);
};

export const getNextFromLogic = (
  step: TemplateStep,
  value: any,
  template: Template
) => {
  if (!template.logic) return;
  if (!step.id) return;
  if (!template.logic[step.id]) return;
  if (!value && step.step_type !== "checklist" && step.step_type !== "divider")
    return;
  const logic = template.logic[step.id];
  if (!logic || !logic.length) return;
  const rules = logic.filter((r: any) => {
    if (!r.alwaysJump && !r.conditionalJump) return false;
    return true;
  });
  if (!rules.length) return;
  rules.sort((a: any, b: any) => a.order - b.order);
  let jumpTo: any;
  const reworkedValue = Array.isArray(value)
    ? !step.multiple_choice && value?.length
      ? value[0]
      : value.map((a) => `${a}`)
    : value;
  for (const rule of rules) {
    let checkString = `(true`;
    if (reworkedValue) {
      if (rule.conditions.length === 0) {
        checkString += " && false";
      }
      rule.conditions.forEach((c: RuleCondition) => {
        if (c.conjunction === "or") checkString += ") || (true";
        checkString += ` && ${getCheckResult(
          c.operator,
          reworkedValue,
          c.value
        )}`;
      });
    } else {
      checkString += " && false";
    }
    checkString += ")";
    jumpTo = rule.alwaysJump;
    if (eval(checkString)) {
      jumpTo = rule.conditionalJump;
      break;
    }
  }
  return jumpTo;
};

export const getAnswerValue = (answer: ExecutionAnswer | undefined) => {
  if (!answer) return null;
  switch (answer.step.step_type) {
    case "text":
    case "long_text":
    case "date":
    case "number":
    case "indicator":
      return answer.value;
    case "attachment": {
      return answer.files;
    }
    case "multiple_choice":
    case "picture_choice":
    case "audit": {
      return answer.choices;
    }
    default: {
      return null;
    }
  }
};

export function stepHasNOKAnswer(step: TemplateStep, answer: number) {
  if (!step.choices?.length) return false;
  let choice;
  const min = step.choices.reduce((acc, v) => {
    if (v.id === answer) choice = v.score;
    if (v.score < acc) return v.score;
    return acc;
  }, Infinity);
  return min === choice;
}
