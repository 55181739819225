import { Filesystem, Directory } from "@capacitor/filesystem";
import { AuthType, Token } from "@/types";

export const writeFile = async (path: string, data: string) => {
  await Filesystem.writeFile({
    path,
    data,
    directory: Directory.Documents,
  });
};

export const readFile = async (path: string) => {
  const contents = await Filesystem.readFile({
    path,
    directory: Directory.Documents,
  });
  return contents.data;
};

export const deleteFile = async (path: string) => {
  await Filesystem.deleteFile({
    path,
    directory: Directory.Documents,
  });
};

export const existsFile = async (path: string) => {
  try {
    const stat = await Filesystem.stat({
      path,
      directory: Directory.Documents,
    });
    if (!stat || !stat.uri) return false;
    return stat;
  } catch (e) {
    console.error("File not found", e);
  }
  return false;
};

export const downloadFile = async (url: string, path: string) => {
  return Filesystem.downloadFile({
    url,
    path,
    directory: Directory.Documents,
  }).then(({ path }) => path);
};

const storageLegacyPathnamePrefix = "/legacy/storage";
const thumbnailsLegacyPathnamePrefix = "/legacy/thumbnails";

export const transformUrl = (
  url: string | null | undefined,
  token: Token | null,
  subdomain: string | null
) => {
  if (!url || !token) return url;
  if (token?.type !== AuthType.Future) return url;
  const urlObject = new URL(url);
  if (urlObject.hostname.startsWith("storage")) {
    return `${subdomain}${storageLegacyPathnamePrefix}${urlObject.pathname}${urlObject.search}`;
  }
  if (urlObject.hostname.startsWith("thumbnails")) {
    return `${subdomain}${thumbnailsLegacyPathnamePrefix}${urlObject.pathname}${urlObject.search}`;
  }
  return url;
};
