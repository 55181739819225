import { defineStore, storeToRefs } from "pinia";
import { computed, type ComputedRef, type Ref, ref } from "vue";
import {
  type EventProperty,
  type EventPropertyFilter,
  type EventPropertyWithReadableValue,
  type EventType,
  type EventTypeProperty,
  type FabriqEvent,
  type FabriqEventPayload,
  type FabriqEventWithReadableProperties,
  Field,
  type LocalizedEventTypeProperty,
  Team,
} from "@/types";
import { type Localizable, localize } from "@/utils/localize";

import { useEventTypesStore } from "./eventTypes";
import { filterEvent } from "@/utils/filter_event";
import { useFabriqStore } from "./fabriq";
import { useEventsWithPropertiesStore } from "./eventsWithProperties";
import { useTeamsStore } from "./teams";
import { useClientStore } from "./client";

export const useEventFiltersStore = defineStore("eventFilters", () => {
  const eventsWithPropertiesStore = useEventsWithPropertiesStore();
  const eventTypeStore = useEventTypesStore();
  const teamsStore = useTeamsStore();
  const clientStore = useClientStore();

  const { locale, user } = useFabriqStore();

  const {
    eventsWithProperties,
    allEventTypesOrdinalPropertiesValues,
    allEventTypesCardinalPropertiesValues,
    allEventTypesDateTimeProperties,
    eventTypePropertiesMap,
  } = storeToRefs(eventsWithPropertiesStore);
  const { collection: eventTypes } = storeToRefs(eventTypeStore);

  const filters = ref<EventPropertyFilter[]>([]);
  const eventTypeId = ref<string | null>(null);

  const teams: ComputedRef<Team[]> = computed(() => {
    if (!teamsStore.collection?.length) return [];
    return teamsStore.collection.map((t: Team) => {
      return {
        ...t,
        color: t.config?.color,
        icon: t.config?.icon,
      };
    });
  });

  const availableZones: Ref<Field[]> = computed(() => {
    const zonesOfEventsById: Map<string, Field> = new Map();

    // Extra fields are either linked to a group, or to the client itself.
    // In order to get the extra fields of a ticket, we must get the extra fields
    // of any of its teams, which are the sum of the extra fields of their groups
    // and the extra fields of the client.
    const idsOfGroupsOfAnyTeam = new Set([
      ...teams.value.flatMap((team: Team) => {
        return team.groups;
      }),
      ...clientStore.groups
        .filter(
          (group) => user?.id !== undefined && group.admins.includes(user.id)
        )
        .map((group) => group.id),
    ]);

    const allZones: Field[] = [
      ...clientStore.groups
        .filter((group) => idsOfGroupsOfAnyTeam.has(group.id))
        .flatMap((group) =>
          group.extra_fields.filter((v: Field) => v.type === "zone")
        ),
      ...clientStore.extra_fields.filter((v: Field) => v.type === "zone"),
    ];

    const allUsableZones: Field[] = teams.value
      .flatMap((team: Team) => team.extra_fields)
      .filter((id) => allZones.some((zone) => zone.id === id))
      .flatMap((id) => [id, ...getZoneIdWithChildren(id, allZones)])
      .map((id) => allZones.find((zone) => zone.id === id) as Field);

    allUsableZones.forEach((zone) => {
      zonesOfEventsById.set(`${zone.id}`, zone);
    });

    return Array.from(zonesOfEventsById.values()).map((v: any) => ({
      ...v,
      name: v?.config?.i18n?.[locale] || v.name,
    }));
  });

  const eventTypeProperties: Ref<EventTypeProperty[]> = ref([]);

  const filterProperties: Ref<LocalizedEventTypeProperty[]> = ref([]);
  const isCompleteFilter: Ref<boolean | undefined> = ref();

  const page = ref(0);
  const asc = ref(false);
  const sortProperty = ref<string | undefined>();
  const teamId = ref<number>();

  const eventList: ComputedRef<FabriqEventWithReadableProperties[]> = computed(
    () => {
      const events = eventsWithProperties.value;
      const eventTypePropertiesMapValue = eventTypePropertiesMap.value;
      const eventTypeIdValue = eventTypeId.value;
      const list = events
        .filter((e: FabriqEvent) =>
          filterEvent(
            e,
            eventTypeIdValue,
            filters.value,
            isCompleteFilter.value
          )
        )
        .map((e: FabriqEvent) => {
          const properties = e.properties
            .sort((a, b) => {
              const orderA =
                eventTypePropertiesMapValue[a.eventPropertyTypeId]?.order;
              const orderB =
                eventTypePropertiesMapValue[b.eventPropertyTypeId]?.order;
              return orderA - orderB;
            })
            .map((property) => {
              return eventPropertyToReadableEventProperty(
                e,
                property,
                eventTypePropertiesMap,
                allEventTypesOrdinalPropertiesValues,
                allEventTypesCardinalPropertiesValues,
                availableZones,
                locale
              );
            });

          return { ...e, properties };
        });
      return list;
    }
  );

  function loadEventListWithFilters(options?: { flush: boolean }) {
    const eventTypeFilters = eventTypeId.value
      ? filters.value.length
        ? [
            ...filters.value.map((f, index) => {
              return {
                ...f,
                ...(index ? { conjunction: "AND" } : {}),
              };
            }),
          ]
        : getFilterForAllEventTypes(eventTypeId.value)
      : getFilterForAllEventTypes();
    return eventsWithPropertiesStore.retrieveEvents(
      eventTypeId.value,
      eventTypeFilters,
      isCompleteFilter.value,
      page.value,
      asc.value,
      sortProperty.value,
      teamId.value,
      allEventTypesDateTimeProperties.value,
      options?.flush ?? false
    );
  }

  async function setEventTypeId(id: string | null, load = true) {
    resetFilters();
    eventTypeId.value = id;
    eventTypeProperties.value = [];
    filterProperties.value = [];
    sortProperty.value = undefined;
    if (id) {
      eventTypeProperties.value =
        eventTypes.value.find((eventType: EventType) => eventType.id === id)
          ?.properties ?? [];
      filterProperties.value = [
        ...eventTypeProperties.value.map((p) => {
          if (p.type === "ordinal" || p.type === "cardinal") {
            return {
              ...p,
              label: localize(p.label, locale, 0) ?? "",
              values: p.values.map((v) => ({
                ...v,
                label: localize(v.label, locale, 0) ?? "",
              })),
            } as LocalizedEventTypeProperty;
          }
          return {
            ...p,
            label: localize(p.label, locale, 0) ?? "",
          };
        }),
      ];
      sortProperty.value = eventTypeProperties.value.find(
        (p) => p.type === "datetime"
      )?.id;
    }
    if (load) await loadEventListWithFilters();
  }

  function resetFilters() {
    filters.value = [];
    page.value = 0;
  }
  function setZoneId(zoneId: number | null) {
    if (!eventTypeId.value) return;
    const zoneProperty = eventTypeProperties.value.find(
      (ep) => ep.type === "zone"
    );
    if (!zoneProperty) return;
    filters.value =
      zoneId === null || zoneId === undefined
        ? []
        : [
            {
              conjunction: "AND",
              id: zoneProperty.id,
              operator: "==",
              values: [zoneId],
            },
          ];
    page.value = 0;
    return loadEventListWithFilters();
  }
  function loadMore() {
    page.value++;
    return loadEventListWithFilters();
  }

  function filteredZoneFromEventType(eventType: EventType) {
    const groupIds = new Set(eventType.groupIds);
    const zones = new Map<number, Field>();
    availableZones.value.forEach((z) => {
      if (z.groups.some((g) => groupIds.has(g))) {
        zones.set(z.id, z);
      }
    });
    return [...zones.values()];
  }

  function getFilterForAllEventTypes(eventTypeId?: string) {
    const allFilters: EventPropertyFilter[] = [];
    eventTypes.value.forEach((eventType: EventType) => {
      if (eventTypeId && eventType.id !== eventTypeId) return;
      const zoneProperty = eventType.properties.find(
        (p: EventTypeProperty) => p.type === "zone"
      );
      if (!zoneProperty) return;
      const values = filteredZoneFromEventType(eventType).map((z) => z.id);
      if (!values.length) return;
      allFilters.push({
        id: zoneProperty.id,
        operator: "==",
        values,
        ...(allFilters.length ? { conjunction: "OR" } : {}),
      });
    });
    return allFilters;
  }

  function canUserEdit(eventId: string) {
    const event = eventsWithProperties.value.find((e) => e.id === eventId);
    if (!event) return false;
    const eventType = eventTypes.value.find(
      (et: EventType) => et.id === event.eventTypeId
    );
    if (!eventType) return false;
    const zoneProperty = eventType.properties.find(
      (p: EventTypeProperty) => p.type === "zone"
    );
    if (!zoneProperty) return false;
    const zoneId = Number(
      event.properties.find((p) => p.eventPropertyTypeId === zoneProperty.id)
        ?.value ?? 0
    );
    if (!zoneId) return false;
    const zoneTeams = teams.value.filter((t) =>
      t.extra_fields.includes(zoneId)
    );
    const zoneAccessFromTeams = zoneTeams.some((t) => {
      if (t.admins.includes(user?.id ?? 0)) return true;
      if (t.auditors.includes(user?.id ?? 0)) return false;
      if (t.users.includes(user?.id ?? 0)) return true;
      return t.groups.some((g) => {
        const group = clientStore.groups.find((group) => group.id === g);
        if (!group) return false;
        return group.admins.includes(user?.id ?? 0);
      });
    });

    if (zoneAccessFromTeams) return true;

    const hasAccessFromGroupAdminStatus =
      clientStore.groups
        .filter((group) => group.admins.includes(user?.id ?? 0))
        .flatMap((group) =>
          group.extra_fields.filter(
            (v: Field) => v.type === "zone" && v.id === zoneId
          )
        ).length > 0;
    return hasAccessFromGroupAdminStatus;
  }

  return {
    eventList,
    filterProperties,
    eventTypeId,
    resetFilters,
    setEventTypeId,
    eventTypeProperties,
    filters,
    sortProperty,
    loadMore,
    sortOrderAscending: asc,
    setZoneId,
    availableZones,
    filteredZoneFromEventType,
    canUserEdit,
    loadEventListWithFilters,
  };
});

export function getZoneIdWithChildren(
  masterZoneId: number,
  zones: Field[]
): number[] {
  const children = zones
    .filter((z) => z.parent === masterZoneId)
    .flatMap((z) => getZoneIdWithChildren(z.id, zones));
  return [masterZoneId, ...children];
}

export function eventPropertyToReadableEventProperty(
  event: FabriqEventPayload,
  property: EventProperty,
  eventTypePropertiesMap: Ref<Record<string, EventTypeProperty>>,
  allEventTypesOrdinalPropertiesValues: Ref<
    Record<
      string,
      Record<
        string,
        {
          rank: number;
          label: Localizable;
          color?: string | undefined;
        }[]
      >
    >
  >,
  allEventTypesCardinalPropertiesValues: Ref<
    Record<
      string,
      Record<
        string,
        {
          value: string;
          label: Localizable;
          color?: string | undefined;
        }[]
      >
    >
  >,
  availableZones: Ref<Field[]>,
  locale: string
): EventPropertyWithReadableValue {
  const eventProperty =
    eventTypePropertiesMap.value[property.eventPropertyTypeId];

  if (!eventProperty) {
    return { ...property, readableValue: "" };
  }
  return {
    ...property,
    readableValue: getReadableValue(
      property.value,
      event.eventTypeId,
      eventProperty,
      allEventTypesOrdinalPropertiesValues,
      allEventTypesCardinalPropertiesValues,
      availableZones,
      locale
    ),
  };
}

export function getReadableValue(
  value: string | number | null | undefined,
  eventTypeId: string,
  eventTypeProperty: EventTypeProperty,
  allEventTypesOrdinalPropertiesValues: Ref<
    Record<
      string,
      Record<
        string,
        {
          rank: number;
          label: Localizable;
          color?: string | undefined;
        }[]
      >
    >
  >,
  allEventTypesCardinalPropertiesValues: Ref<
    Record<
      string,
      Record<
        string,
        {
          value: string;
          label: Localizable;
          color?: string | undefined;
        }[]
      >
    >
  >,
  availableZones: Ref<Field[]>,
  locale: string
): string {
  switch (eventTypeProperty.type) {
    case "datetime":
      return value !== undefined && value !== null ? `${value}` : "";
    case "ordinal": {
      const ordinalPropertyValues =
        allEventTypesOrdinalPropertiesValues.value[eventTypeId];
      if (!ordinalPropertyValues) throw new Error("Ordinal values not found");
      const readableValue = (
        ordinalPropertyValues[eventTypeProperty.id] ?? []
      ).find((v) => v.rank === value);
      return readableValue?.label
        ? localize(readableValue.label, locale, 0) ?? ""
        : "";
    }
    case "cardinal": {
      const cardinalPropertyValues =
        allEventTypesCardinalPropertiesValues.value[eventTypeId];
      if (!cardinalPropertyValues) throw new Error("Cardinal values not found");
      const readableValue = (
        cardinalPropertyValues[eventTypeProperty.id] ?? []
      ).find((v) => v.value === value);
      return readableValue?.label
        ? localize(readableValue.label, locale, 0) ?? ""
        : "";
    }
    case "zone":
      const zone = availableZones.value.find((z) => z.id === value);
      return zone ? zone.name : "";
    default:
      return value !== undefined && value !== null ? `${value}` : "";
  }
}
