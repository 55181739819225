import { storeToRefs } from "pinia";
import { type Ref, computed, type ComputedRef } from "vue";
import type {
  EventPropertyType,
  EventTypeProperty,
  EventProperty,
  EventPropertyWithReadableValue,
  FabriqEvent,
  FabriqEventWithReadableProperties,
  EventType,
} from "@/types";
import { useEventsWithPropertiesStore } from "@/store/eventsWithProperties";
import { localize } from "@/utils/localize";
import { useFabriqStore } from "@/store/fabriq";

export type EventPropertyWithReadableValueWithEventTypePropertyLabel =
  EventPropertyWithReadableValue & {
    propertyLabel: string;
  };

export function useGetEventPropertiesByType(event: Ref<FabriqEvent>) {
  const { locale } = useFabriqStore();
  const eventsWithProperties = useEventsWithPropertiesStore();
  const { eventTypes, eventTypePropertiesMap } =
    storeToRefs(eventsWithProperties);

  const eventType: ComputedRef<EventType> = computed(() =>
    eventTypes.value.find((t: EventType) => t.id === event.value.eventTypeId)
  );

  function getPropertyTypeByEventPropertyTypeId(
    eventPropertyTypeId: string
  ): EventPropertyType {
    return eventTypePropertiesMap.value[eventPropertyTypeId]?.type;
  }

  function getPropertyByEventPropertyTypeId(
    eventPropertyTypeId: string
  ): EventTypeProperty | undefined {
    return eventTypePropertiesMap.value[eventPropertyTypeId];
  }

  function getEventPropertiesByType(
    event: FabriqEventWithReadableProperties,
    type: EventPropertyType
  ): EventPropertyWithReadableValueWithEventTypePropertyLabel | undefined {
    const eventType = eventTypes.value.find(
      (t: EventType) => t.id === event.eventTypeId
    );
    if (!eventType) {
      return;
    }
    const eventTypeProperty = eventType.properties.find(
      (p: EventTypeProperty) => p.type === type
    );
    if (!eventTypeProperty) {
      return;
    }
    const eventProperty = event.properties.find(
      (p: EventProperty) => p.eventPropertyTypeId === eventTypeProperty?.id
    );
    if (!eventProperty) {
      return;
    }
    return {
      ...eventProperty,
      propertyLabel: localize(eventTypeProperty.label, locale, 0) ?? "",
      type,
    } as EventPropertyWithReadableValueWithEventTypePropertyLabel;
  }
  return {
    eventType,
    getEventPropertiesByType,
    getPropertyTypeByEventPropertyTypeId,
    getPropertyByEventPropertyTypeId,
  };
}
