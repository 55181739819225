<script lang="ts" setup>
import { computed, PropType } from "vue";
import { FilledInstance, RoutineInstance, RoutineScope, User } from "@/types";
import { IonItem, IonLabel } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { useCanStartRoutine } from "@/composables/useCanStartRoutine";
import { useDateString } from "@/composables/useDateString";
import AvatarList from "@/components/business/AvatarList.vue";
import ScoreDisk from "@/components/ui/ScoreDisk.vue";
import RoutineInstanceScope from "@/components/business/routines/RoutineInstanceScope.vue";

const emit = defineEmits(["goto", "reload"]);
const props = defineProps({
  instance: {
    type: Object as PropType<FilledInstance>,
    default: () => ({}),
  },
  users: {
    type: Array as PropType<Array<User>>,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const { t } = useI18n();
const dayOfMonthFormat = t("formats.dayOfMonth");
const dateString = useDateString(props.instance, dayOfMonthFormat);
const canStartRoutine = useCanStartRoutine(props.instance);

const gotoInstance = async () => {
  if (!canStartRoutine.value) return;
  emit("goto", props.instance);
};

const score = computed(() => {
  return +(props.instance.score?.realized || 0);
});

const potential = computed(() => {
  return +(props.instance.score?.potential || 0);
});

function getInstanceScope(instance: RoutineInstance): RoutineScope {
  return {
    auditee: instance.scheduler?.auditee || null,
    zone: instance.scheduler?.properties?.zone || [],
    product: instance.scheduler?.properties?.product || [],
    equipment: instance.scheduler?.properties?.equipment || [],
  };
}
</script>

<template>
  <ion-item
    lines="none"
    class="f-list-item"
    :class="{ 'look-disabled': instance.disabled }"
  >
    <avatar-list
      class="ion-margin-end start"
      :ids="instance.users"
      :users="users"
    />
    <ion-label class="instance-title">
      {{ instance.routine?.title || t("common.noTitle") }}
      <div class="hint">{{ dateString }}</div>
      <RoutineInstanceScope
        class="instance-scope"
        :scope="getInstanceScope(instance as RoutineInstance)"
      />
    </ion-label>
    <score-disk
      v-if="instance.is_done && potential > 0 && instance.execution_end_date"
      style="margin-right: 6px"
      :size="32"
      :score="score"
      :max="potential"
    />
    <font-icon
      v-if="instance.is_done && instance.execution_end_date"
      class="done-instance"
      name="check_circle"
      size="1.5"
      material
      color="var(--ion-color-success)"
    />
    <div
      class="start-instance"
      :class="{
        'look-disabled': !canStartRoutine,
        clickable: canStartRoutine,
      }"
      v-else-if="!instance.is_done && instance.has_started"
      @click="gotoInstance"
    >
      <font-icon
        name="play_arrow"
        size="0.875"
        color="var(--ion-color-secondary)"
        material
      />
      {{ t("resume") }}
    </div>
    <div
      class="start-instance"
      @click="emit('reload')"
      :class="{ 'look-disabled': loading }"
      v-else-if="instance.is_done && !instance.execution_end_date"
    >
      <font-icon
        slot="end"
        class="done-instance"
        :name="loading ? 'refresh' : 'error'"
        size="0.875"
        material
        :color="
          loading ? 'var(--ion-color-primary)' : 'var(--ion-color-danger)'
        "
      />
      {{ loading ? t("common.uploading") : t("common.notSent") }}
    </div>
    <div
      class="start-instance"
      :class="{
        'look-disabled': !canStartRoutine,
        clickable: canStartRoutine,
      }"
      @click="gotoInstance"
      v-else
    >
      <font-icon
        name="play_arrow"
        size="0.875"
        color="var(--ion-color-secondary)"
        material
      />
      {{ t("start") }}
    </div>
  </ion-item>
</template>

<style scoped>
ion-item {
  --f-border-radius: 5px;
  --padding-start: var(--ion-padding);
  --padding-end: 0;
}

.instance-scope {
  font-size: var(--font-size-s);
  margin-top: calc(var(--ion-padding) / 2);
}

.instance-title {
  font-size: var(--font-size-m);
}

.instance-title .hint {
  margin-top: calc(var(--ion-padding) / 2);
}
</style>
