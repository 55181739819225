import { ApiClient } from "./clients";
import {
  DetailedValue,
  Execution,
  ExecutionAnswer,
  ExecutionInfo,
  ListService,
  PinnedAnswer,
} from "@/types";
import { useAnswersStore } from "@/store/answers";
import { useFabriqStore } from "@/store/fabriq";

type Answer = {
  id?: string | number | null;
  value?: string | null;
  choices: number[] | null;
  execution_info?: ExecutionInfo;
  detailed_value?: DetailedValue;
  pinned: PinnedAnswer;
  score: string | undefined;
};

export interface ExecutionListService extends ListService<Execution> {
  create?: (payload: { id: number; start_date: string }) => Promise<Execution>;
}
export const ExecutionService: ExecutionListService = {
  async update(entity: Execution, columns: any): Promise<Execution> {
    const answersStore = useAnswersStore();
    const executionAnswers = answersStore.collection.filter(
      (a: ExecutionAnswer) => a.instance_execution === entity.id
    );
    const answers: Answer[] = executionAnswers.map((a: ExecutionAnswer) => {
      return {
        id: a.id,
        value: a.value,
        choices: a.choices,
        execution_info: a.execution_info,
        detailed_value: a.detailed_value,
        pinned: a.pinned,
        score: a.score,
      };
    });
    try {
      await ApiClient.put(`/api/v1/routine/answers/`, answers);
      const execution = await ApiClient.put(
        `/api/v1/instance/execution/${entity.id}/`,
        { ...columns }
      );
      return { ...entity, ...columns, ...execution };
    } catch (error) {
      const fabriqStore = useFabriqStore();
      const message = (error as Error)?.message ?? error ?? "Unknown error";
      const executionError = new Error(
        `Error updating execution ${entity.id}: ${message}`
      );
      fabriqStore.reportSentry(executionError);
      throw error;
    }
  },
  async load(id: number): Promise<Execution | null> {
    const result = await ApiClient.get(`/api/v1/instance/${id}/`, {
      fetch_execution: "1",
    });
    if (!result || !result.execution) return Promise.reject(null);
    return { ...result.execution, updated_at: result.execution.start_date };
  },
  async add(payload: {
    instance: number | string;
    start_date: string;
  }): Promise<Execution> {
    const execution = await ApiClient.post(
      `/api/v1/instance/${payload.instance}/execution/`,
      {
        ongoing: true,
        start_date: payload.start_date,
      }
    );
    return execution;
  },
};
