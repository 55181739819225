import { FilledInstance } from "@/types";
import { format, parseISO } from "date-fns";
import { computed, ComputedRef } from "vue";
/**
 * useFilterInstances
 * @param ordered a list of instances already filled
 * @param context can be done, today and later
 * @param today a date to compare with
 * @returns a list of instances that are active at the given time
 */
export const useFilterInstances = (
  ordered: ComputedRef<FilledInstance[]>,
  context: "done" | "today" | "later",
  today = format(new Date(), "yyyy-MM-dd")
): ComputedRef<FilledInstance[]> => {
  // To retrieve done instances, no need to sort
  if (context === "done") {
    return computed(() => ordered.value.filter((i) => i.is_done));
  }

  const instances = computed(() => {
    return ordered.value.filter((i) => {
      if (!i.start_date || !i.end_date || i.is_done) return false;
      const start = format(parseISO(i.start_date), "yyyy-MM-dd");
      const end = format(parseISO(i.end_date), "yyyy-MM-dd");

      // Sort routines that are active today
      if (context === "today") {
        return start.localeCompare(today) <= 0 && end.localeCompare(today) >= 0;
      }

      // Sort routines that are active later
      if (context === "later") {
        return start.localeCompare(today) > 0;
      }
    });
  });

  return instances;
};
