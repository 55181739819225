<template>
  <div>
    <div
      class="ion-text-start question question-step ion-margin-horizontal"
      v-if="component === 'StepChecklist'"
    >
      {{ currentStep }}. {{ step.question }}
      <span v-if="!step.is_optional">*</span>
    </div>
    <div class="instance-step f-list-item">
      <div class="ion-text-start question" v-if="component !== 'StepChecklist'">
        {{ currentStep }}. {{ step.question }}
        <span v-if="!step.is_optional">*</span>
      </div>
      <ion-row v-if="category" class="ion-margin-top ion-justify-content-start">
        <category-chip :category="category" :readonly="true" />
      </ion-row>
      <file-list
        v-if="step.files && step.files.length"
        class="ion-justify-content-center ion-margin-bottom"
        :files="step.files"
        :readonly="true"
        :viewer="true"
      />

      <HtmlContent
        v-if="step.instruction && step.instruction.length > 0"
        class="ion-margin-top instruction"
        tag="div"
        :content="step.instruction"
      />

      <component
        v-if="step.step_type !== 'divider'"
        :class="{ 'ion-margin-top': component !== 'StepChecklist' }"
        :is="component"
        :step="step"
        :answer="answer"
        :answers="answers"
        :comments="comments"
        :files="files"
        :template="template"
        :routine="routine"
        :execution-date="executionDate"
        :key="step.id"
        @checklist="$emit('checklist', $event)"
        @select="$emit('select', $event)"
        @value="value"
        @detailed_value="detailedValue"
        @file="$emit('file', $event)"
        @comment="$emit('comment', $event)"
      />
      <div v-if="step.step_type !== 'divider'" class="step-actions">
        <div
          class="step-action"
          :class="{
            'look-disabled': incomplete,
            'animation-pulse pulse': hasNudgeCreateTicketActive,
          }"
          @click="openAddMenu()"
        >
          <font-icon
            slot="icon-only"
            name="post_add"
            material
            :color="
              hasNudgeCreateTicketActive
                ? 'var(--ion-color-secondary)'
                : 'var(--ion-color-primary-shade)'
            "
            size="0.8"
          />
          {{ t("ticket", 1) }}
        </div>
        <div
          class="step-action"
          :class="{ highlight: files && files.length }"
          v-if="component !== 'StepAttachment'"
          @click="$emit('file')"
        >
          <font-icon
            slot="icon-only"
            name="attach_file"
            material
            :color="
              files && files.length
                ? 'var(--ion-color-primary)'
                : 'var(--ion-color-primary-shade)'
            "
            size="0.8"
          />
          {{ t("common.media") }}
          <span v-if="files && files.length"> ({{ files.length }}) </span>
        </div>
        <div
          class="step-action"
          :class="{ good: pinnedUp, bad: pinnedDown }"
          @click="openHightlight"
        >
          <font-icon
            slot="icon-only"
            name="thumbs_up_down"
            material
            size="0.8"
            color="var(--ion-color-primary-shade)"
            v-if="!pinnedUp && !pinnedDown"
          />
          <font-icon
            slot="icon-only"
            name="thumb_up"
            material
            size="0.8"
            color="var(--ion-color-success)"
            v-else-if="pinnedUp"
          />
          <font-icon
            slot="icon-only"
            name="thumb_down"
            material
            size="0.8"
            color="var(--ion-color-danger)"
            v-else
          />
          <span v-if="!pinnedUp && !pinnedDown">
            {{ t("common.highlight") }}
          </span>
          <span v-else-if="pinnedUp">{{ t("common.good") }}</span>
          <span v-else-if="pinnedDown">{{ t("common.bad") }}</span>
        </div>
        <div
          class="step-action"
          :class="{ highlight: nbComments }"
          @click="$emit('comment')"
        >
          <font-icon
            slot="icon-only"
            name="chat_bubble_outline"
            material
            :color="
              nbComments
                ? 'var(--ion-color-primary)'
                : 'var(--ion-color-primary-shade)'
            "
            size="0.8"
          />
          {{ t("common.comment") }}
          <span v-if="nbComments"> ({{ nbComments }}) </span>
        </div>
      </div>
    </div>
    <div v-if="component !== 'StepAttachment' && files && files.length">
      <file-list class="answers-files" :viewer="true" :files="files" />
    </div>
  </div>
</template>

<script lang="ts">
import {
  Camera,
  type CameraPluginPermissions,
  CameraResultType,
} from "@capacitor/camera";
import { IonButton, IonRow, modalController, useIonRouter } from "@ionic/vue";
import {
  computed,
  defineComponent,
  type PropType,
  ref,
  toRaw,
  ComputedRef,
} from "vue";
import { useI18n } from "@/composables/useI18n";
import { addDays, format, formatISO } from "date-fns";
import {
  type Category,
  DependencyType,
  type DetailedValue,
  type ExecutionAnswer,
  type Instance,
  MediaType,
  ObjectType,
  PinnedAnswer,
  type Routine,
  RoutineInstance,
  RoutineScope,
  StepType,
  type Team,
  type Template,
  type TemplateStep,
  type Ticket,
  type TicketTemplate,
  TicketType,
  Indicator,
  Dependency,
} from "@/types";
import { useFabriqStore } from "@/store/fabriq";
import { useFilesStore } from "@/store/files";
import { useTicketsStore } from "@/store/tickets";
import { useCommentsStore } from "@/store/comments";
import StepAudit from "@/components/business/routines/steps/StepAudit.vue";
import StepNumber from "@/components/business/routines/steps/StepNumber.vue";
import StepText from "@/components/business/routines/steps/StepText.vue";
import StepLongText from "@/components/business/routines/steps/StepLongText.vue";
import StepMultiple from "@/components/business/routines/steps/StepMultiple.vue";
import StepPicture from "@/components/business/routines/steps/StepPicture.vue";
import StepChecklist from "@/components/business/routines/steps/StepChecklist.vue";
import StepDate from "@/components/business/routines/steps/StepDate.vue";
import StepAttachment from "@/components/business/routines/steps/StepAttachment.vue";
import StepDivider from "@/components/business/routines/steps/StepDivider.vue";
import StepKpi from "@/components/business/routines/steps/kpi/StepKpi.vue";
import FileList from "@/components/business/FileList.vue";
import CategoryChip from "@/components/business/tickets/CategoryChip.vue";
import RoutineStepHighlightModal from "@/components/modals/RoutineStepHighlightModal.vue";
import TicketCreateModal from "@/components/modals/TicketCreateModal.vue";
import { ticketPageAnimation } from "@/utils/animations";
import { useClientStore } from "@/store/client";
import { useTeamsStore } from "@/store/teams";
import { useTicketTemplatesStore } from "@/store/ticketTemplates";
import { calcModalPercents } from "@/utils/modals";
import { addTicketTemplatePresetValues } from "@/utils/addTicketTemplatePresetValues";
import { createEmptyTicket } from "@/utils/createEmptyTicket";
import { getTextToDisplay } from "../tickets/services/getTextToDisplay";
import { useTasksStore } from "@/store/tasks";
import { useFieldsStore } from "@/store/fields";
import { useIndicatorsStore } from "@/store/indicators";
import { getUserRole } from "@/utils/user-rights";
import { useRoutineNudgeCreateTicket } from "@/composables/useRoutineNudgeCreateTicket";
import { useRoutineStepCheckList } from "@/composables/useRoutineStepCheckList";
import HtmlContent from "@/components/tools/HtmlContent.vue";

export default defineComponent({
  name: "InstanceStep",
  components: {
    IonRow,
    IonButton,
    StepAudit,
    StepNumber,
    StepText,
    StepLongText,
    StepMultiple,
    StepPicture,
    StepDate,
    StepAttachment,
    StepDivider,
    StepChecklist,
    StepKpi,
    FileList,
    CategoryChip,
    HtmlContent,
  },
  emits: [
    "select",
    "value",
    "detailed_value",
    "checklist",
    "pin",
    "comment",
    "file",
  ],
  props: {
    instance: {
      type: Object as PropType<Instance>,
      default: () => ({}),
    },
    routine: {
      type: Object as PropType<Routine>,
      required: true,
    },
    step: {
      type: Object as PropType<TemplateStep>,
      default: () => ({}),
    },
    answer: {
      type: Object as PropType<ExecutionAnswer>,
      default: () => ({}),
    },
    answers: {
      type: Array as PropType<Array<ExecutionAnswer>>,
      default: () => [],
    },
    template: {
      type: Object as PropType<Template>,
      required: true,
    },
    uncompletedSteps: {
      type: Array as PropType<Array<ExecutionAnswer>>,
      default: () => [],
    },
    currentStep: {
      type: Number,
      default: 1,
    },
    executionDate: {
      type: Date,
      default: new Date(),
    },
  },
  setup(props, { emit }) {
    const fabriqStore = useFabriqStore();
    const clientStore = useClientStore();
    const filesStore = useFilesStore();
    const ticketsStore = useTicketsStore();
    const showMedia = ref(false);
    const router = useIonRouter();
    const { t } = useI18n();
    const commentsStore = useCommentsStore();
    const teamsStore = useTeamsStore();
    const ticketTemplatesStore = useTicketTemplatesStore();
    const tasksStore = useTasksStore();
    const fieldsStore = useFieldsStore();

    function getInstanceScope(instance: RoutineInstance): RoutineScope {
      return {
        auditee: instance.scheduler?.auditee || null,
        zone: instance.scheduler?.properties?.zone || [],
        product: instance.scheduler?.properties?.product || [],
        equipment: instance.scheduler?.properties?.equipment || [],
      };
    }

    function getTeamsForTicket() {
      const teamIds = props.instance.team_id
        ? [props.instance.team_id]
        : props.routine.teams && props.routine.teams.length
        ? toRaw(props.routine.teams)
        : [];
      const roles = ["admin", "owner", "member", "coordinator"];

      const userId = fabriqStore.user?.id;
      if (!userId) return teamIds;

      return teamIds.filter((teamId) => {
        const team = teamsStore.collection.find((t: Team) => t.id === teamId);
        if (!team) return false;
        const role = getUserRole(team, userId, clientStore.tmpNewTeamRoles);
        if (!role) return false;
        return roles.includes(role);
      });
    }

    const value = (value: number) => {
      emit("value", value);
    };

    const indicatorsStore = useIndicatorsStore();
    const indicator: ComputedRef<Indicator> = computed(() => {
      return indicatorsStore.collection.find(
        (indicator: Indicator) => indicator.id === props.step.config.indicatorId
      );
    });

    const isTargetMet = ref<boolean | null>(null);

    const { questions } = useRoutineStepCheckList(
      computed(() => props.template),
      computed(() => props.step),
      computed(() => props.answers),
      computed(() => [])
    );

    const { hasNudgeCreateTicketActive } = useRoutineNudgeCreateTicket(
      computed(() => props.step),
      computed(() => props.answer),
      isTargetMet,
      indicator,
      questions
    );

    const detailedValue = (detailed_value: DetailedValue) => {
      isTargetMet.value = detailed_value.manual_target_met !== false;
      emit("detailed_value", detailed_value);
    };

    const incomplete = computed(() => {
      const idx = props.uncompletedSteps.findIndex((a: any) => {
        return a.id === props.answer.id;
      });
      return idx >= 0;
    });

    const comments = computed(() => {
      return commentsStore.collection.filter((c: any) => {
        if (c.instance !== props.instance.id) return false;
        return c.content.includes(
          `{routineExecutionAnswerId: ${props.answer.id}}`
        );
      });
    });

    const nbComments = computed(() => {
      return comments.value?.length;
    });

    const category = computed(() => {
      if (!props.step || !props.step.category) return null;
      return clientStore.categories?.find(
        (c: Category) => props.step.category === c.id
      );
    });

    const files = computed(() => {
      return filesStore.collection.filter((f: any) => {
        return f.answer === props.answer.id;
      });
    });

    const component = computed(() => {
      switch (props.step.step_type) {
        case StepType.Audit:
          return "StepAudit";
        case StepType.Number:
          return "StepNumber";
        case StepType.Text:
          return "StepText";
        case StepType.LongText:
          return "StepLongText";
        case StepType.Multiple:
          return "StepMultiple";
        case StepType.Picture:
          return "StepPicture";
        case StepType.Date:
          return "StepDate";
        case StepType.Attachment:
          return "StepAttachment";
        case StepType.Divider:
          return "StepDivider";
        case StepType.Checklist:
          return "StepChecklist";
        case StepType.Indicator:
          return "StepKpi";
        default:
          return "StepAudit";
      }
    });

    const pinnedUp = computed(() => props.answer.pinned === PinnedAnswer.Up);
    const pinnedDown = computed(
      () => props.answer.pinned === PinnedAnswer.Down
    );

    const addFile = async () => {
      const permission = await Camera.checkPermissions();
      if (permission.camera !== "granted" || permission.photos !== "granted") {
        const types: CameraPluginPermissions = {
          permissions: ["camera", "photos"],
        };
        await Camera.requestPermissions(types);
      }
      const file = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Uri,
      });

      const now = formatISO(new Date());
      await filesStore.add({
        media_type: MediaType.File,
        _file: { url: file.webPath, path: file.path },
        answer: props.answer.id || props.answer.uuid,
        created_at: now,
        updated_at: now,
      });
      await filesStore.save();
    };

    const openHightlight = async () => {
      const modal = await modalController.create({
        component: RoutineStepHighlightModal,
        canDismiss: true,
        mode: "ios",
        breakpoints: [0, 0.3, 0.5],
        initialBreakpoint: 0.3,
        componentProps: {
          onDone: (action: string) => {
            switch (action) {
              case "up":
                emit("pin", true);
                break;
              case "down":
                emit("pin", false);
                break;
            }
            modal.dismiss();
          },
          onCancel: () => modal.dismiss(),
        },
      });
      return modal.present();
    };

    function getAnswer(answer: ExecutionAnswer) {
      switch (answer.step.step_type) {
        case StepType.YesNo:
        case StepType.Audit:
        case StepType.Multiple: {
          let str = "";
          if (!answer?.choices?.length) return "";
          answer.choices.forEach((id: number) => {
            const choice = answer.step.choices.find((c: any) => c.id === id);
            if (!choice) return;
            if (str.length) str += "<br/>";
            str +=
              answer.step.step_type === StepType.Multiple
                ? choice.text
                : t(`routines.auditStep.${choice.text}`);
          });
          return str;
        }
        case StepType.Number:
        case StepType.Text:
        case StepType.Date:
          return answer.value;
        default:
          return "";
      }
    }

    const openAddMenu = async () => {
      if (!ticketTemplatesStore.collection.length) {
        return addTicket();
      }
      const breakpoints = calcModalPercents(
        44,
        ticketTemplatesStore.collection.length,
        210
      );
      const modal = await modalController.create({
        component: TicketCreateModal,
        canDismiss: true,
        mode: "ios",
        breakpoints,
        initialBreakpoint: breakpoints[1],
        componentProps: {
          onDone: async (response: {
            type: string;
            ticket_template?: number;
          }) => {
            await modal.dismiss();
            switch (response.type) {
              case "ticket":
                addTicket(response.ticket_template);
                break;
            }
          },
          onCancel: async () => {
            await modal.dismiss();
          },
        },
      });
      return modal.present();
    };
    const addTicket = async (ticket_template?: number) => {
      const ticket = await addTicketToStore(ticket_template);
      gotoTicket(ticket.uuid);
    };
    const gotoTicket = (ticket: string) => {
      router.navigate(
        `/tickets/${ticket}/${props.instance.uuid}`,
        "forward",
        "push",
        ticketPageAnimation
      );
    };
    const addTicketToStore = async (ticket_template?: number) => {
      const date = formatISO(new Date());
      const userId = fabriqStore.user?.id;

      let ticketToAdd: Ticket = createEmptyTicket(
        date,
        userId,
        ticket_template
      );
      const template: TicketTemplate | undefined =
        ticketTemplatesStore.collection.find(
          (t: any) => t.id === ticket_template
        );

      if (template !== undefined) {
        ticketToAdd = addTicketTemplatePresetValues(
          ticketToAdd,
          template,
          fabriqStore.locale || "en"
        );
      }
      const dependency =
        props.instance.id && props.instance.scheduler?.team
          ? {
              dependency_type: DependencyType.IsResult,
              related_id: +props.instance.id,
              related_model: ObjectType.Routine,
              related_subelement_id: props.step.id || undefined,
              related_subelement_model: ObjectType.RoutineStep,
            }
          : null;
      const dependencies: Dependency[] = dependency ? [dependency] : [];
      if (
        props.step.step_type === StepType.Indicator &&
        props.step.config.indicatorId
      ) {
        dependencies.push({
          dependency_type: DependencyType.Related,
          related_id: props.step.config.indicatorId,
          related_model: ObjectType.Indicator,
        });
      }
      const title =
        props.routine.title +
        " - " +
        t("routines.step") +
        "#" +
        props.currentStep;
      const description = `<b>${t("routines.question")}</b><br/>${
        props.step.question
      }<br/><br/><b>${t("routines.answer")}</b><br/>${
        getAnswer(props.answer) || ""
      }`;

      const toAdd: Ticket = {
        ...ticketToAdd,
        title,
        description,
        ...(dependencies.length ? { dependencies } : {}),
        teams: [...new Set([...ticketToAdd.teams, ...getTeamsForTicket()])],
        owner: fabriqStore.user?.id || null,
        due_date: format(new Date(), "yyyy-MM-dd"),
        generic_file_ids: files.value.map((f: any) => f.id),
        categories: props.step.category
          ? [props.step.category]
          : ticketToAdd.categories ?? [],
        updated_at: date,
        created_at: date,
        _type: TicketType.Issue,
      };

      const ticket = await ticketsStore.add(toAdd);

      const routineScope = getInstanceScope(props.instance as RoutineInstance);

      routineScope.zone.forEach((zone: number) => {
        fieldsStore.add({
          ticketId: ticket.uuid,
          fieldId: zone,
          type: "zone",
          created_at: formatISO(new Date()),
          updated_at: formatISO(new Date()),
        });
      });
      routineScope.product.forEach((product: number) => {
        fieldsStore.add({
          ticketId: ticket.uuid,
          fieldId: product,
          type: "product",
          created_at: formatISO(new Date()),
          updated_at: formatISO(new Date()),
        });
      });
      routineScope.equipment.forEach((equipment: number) => {
        fieldsStore.add({
          ticketId: ticket.uuid,
          fieldId: equipment,
          type: "equipment",
          created_at: formatISO(new Date()),
          updated_at: formatISO(new Date()),
        });
      });

      if (template?.tasks?.length) {
        const now = new Date();
        let time = 0;
        template.tasks.forEach((t: any) => {
          time += t.resolution_time || 0;
          tasksStore.add({
            ticket: ticket.uuid,
            description: getTextToDisplay(t, fabriqStore.locale),
            user: t.owner_id,
            due_date: t.resolution_time
              ? format(addDays(now, time), "yyyy-MM-dd")
              : null,
            order: t.order,
            created_from_template: true,
          });
        });
      }
      return ticket;
    };

    return {
      files,
      addFile,
      showMedia,
      pinnedUp,
      pinnedDown,
      t,
      comments,
      category,
      component,
      incomplete,
      openHightlight,
      nbComments,
      addTicket,
      openAddMenu,
      value,
      detailedValue,
      isTargetMet,
      hasNudgeCreateTicketActive,
    };
  },
});
</script>

<style scoped>
.file-list {
  --background: transparent;
  --ion-background: transparent;
  --ion-list-background: transparent;
  background-color: transparent;
}

.question {
  font-size: var(--font-size-m);
  font-weight: 500;
}

.instruction {
  font-size: var(--font-size-m);
}

.kpi-label {
  width: fit-content;
  margin-top: 2rem;
  background-color: var(--ion-background-color-shade);
  border: 1px solid var(--ion-border-color);
  color: var(--ion-color-primary);
  border-radius: 50px;
  padding: calc(var(--ion-padding) / 2) var(--ion-padding);
  font-size: var(--font-size-s);
}

.animation-pulse {
  animation: pulse 2s;
  animation-iteration-count: infinite;
  border-radius: 2px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--ion-color-tertiary-rgb), 0.5);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(var(--ion-color-tertiary-rgb), 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(var(--ion-color-tertiary-rgb), 0);
  }
}
</style>
