import { Instance } from "@/types";
import { computed, Ref } from "vue";
import {
  parseISO,
  isWithinInterval,
  format,
  isSameDay,
  isBefore,
  isAfter,
} from "date-fns";

export const useCanStartRoutine = (
  instance: Instance,
  now = new Date()
): Ref<boolean> => {
  return computed(() => {
    if (!instance?.start_date && !instance?.end_date) return true;
    const start = parseISO(instance.start_date || "");
    const end = parseISO(instance.end_date || "");
    if (
      format(start, "HH:mm") === "00:00" &&
      format(end, "HH:mm") === "23:59"
    ) {
      // All day (24 hours routine)
      // automatic or manual
      return (
        isSameDay(now, start) ||
        isSameDay(now, end) ||
        (isBefore(start, now) && isAfter(end, now))
      );
    } else {
      // Not 24 hours routine
      // check if now is within start and end dates
      return isWithinInterval(now, {
        start,
        end,
      });
    }
  });
};
