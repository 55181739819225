import { ExecutionAnswer, Indicator, StepChoice, TemplateStep } from "@/types";
import { stepHasNOKAnswer } from "@/utils/routines";
import { Ref, computed } from "vue";

export const useRoutineNudgeCreateTicket = (
  step: Ref<TemplateStep>,
  answer: Ref<ExecutionAnswer>,
  isTargetMet?: Ref<boolean | null>,
  indicator?: Ref<Indicator>,
  questions?: Ref<(TemplateStep & { answer: ExecutionAnswer })[]>
) => {
  const hasNudgeCreateTicketActive = computed(() => {
    if (step.value?.step_type === "indicator") {
      if (isTargetMet?.value === null) return false;
      return (
        indicator?.value?.config?.nudge_create_ticket && !isTargetMet?.value
      );
    }
    if (
      step.value?.step_type === "audit" ||
      step.value?.step_type === "multiple_choice" ||
      step.value?.step_type === "picture_choice"
    ) {
      if (!step.value?.config?.nudge_create_ticket) return false;
      return stepHasNOKAnswer(
        step.value,
        answer.value.choices?.length ? answer.value.choices[0] ?? -1 : -1
      );
    }
    if (step.value?.step_type === "checklist") {
      if (!step.value?.config?.nudge_create_ticket) return false;
      if (!questions?.value.every((child) => child.answer?.choices?.length))
        return false;
      return (questions?.value ?? []).some((child) => {
        const answer = child.answer;
        if (!answer) return false;
        const choiceId = answer.choices?.[0];
        if (!choiceId) return false;
        const choice = child.choices.find((c: StepChoice) => c.id === choiceId);
        if (!choice) return false;
        return stepHasNOKAnswer(child, choice.id ?? -1);
      });
    }
    return step.value?.config?.nudge_create_ticket;
  });

  return {
    hasNudgeCreateTicketActive,
  };
};
