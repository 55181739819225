<script lang="ts" setup>
import { computed, onMounted, ref, Ref } from "vue";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonInput,
  IonItem,
  IonList,
} from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import ColoredChip from "@/components/ui/ColoredChip.vue";

import { useModalScroller } from "@/composables/useModalScroller";
import { useTeamsStore } from "@/store/teams";
import { Field, Routine, Team, User } from "@/types";
import { useClientStore } from "@/store/client";
import FieldValueSelector from "@/components/business/routines/FieldValueSelector.vue";

interface FullNamedUser extends User {
  fullName: string;
}

const { t } = useI18n();
const teamsStore = useTeamsStore();
const clientStore = useClientStore();
const filter = ref("");
const setting: Ref<"team" | "routineScope"> = ref("team");
const teamId: Ref<number | null> = ref(null);

const auditee: Ref<number | null> = ref(null);
const properties: Ref<Record<string, number[]>> = ref({});

function handleUpdateAuditee(selectedValues: number[]) {
  if (selectedValues.length === 0) auditee.value = null;
  else auditee.value = selectedValues[0];
}

function handleUpdateProperties(type: string, values: number[]) {
  properties.value = { ...properties.value, [type]: values };
}

onMounted(() => {
  if (props.routine.teams.length === 1) {
    setTeam(props.routine.teams[0]);
  }
});

const emit = defineEmits(["done", "cancel"]);
const props = defineProps<{
  routine: Routine;
}>();

const setTeam = (id: number) => {
  teamId.value = id;
  if (props.routine.config?.promptScopeSelectionModal) {
    setting.value = "routineScope";
  } else {
    emit("done", { teamId: id });
  }
  filter.value = "";
};

function validateScopeSelection() {
  emit("done", {
    teamId: teamId.value,
    auditee: auditee.value,
    properties: properties.value,
  });
}

const teams = computed(() => {
  const search = filter.value.toLocaleLowerCase();
  const teams: Team[] = [];
  teamsStore.collection.forEach((t: Team) => {
    if (!props.routine.teams.includes(t.id)) return;
    if (!t.name.toLocaleLowerCase().includes(search)) return;
    teams.push(t);
  });
  teams.sort((a: Team, b: Team) => {
    return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
  });
  return teams;
});

const allFieldsForRoutine = computed(() => {
  const allFieldsWithDuplicates: Field[] = clientStore.groups
    .flatMap((group) => group.extra_fields)
    .filter((field) => field.id);

  const uniqueIds: number[] = [
    ...new Set(allFieldsWithDuplicates.map((field) => parseInt(`${field.id}`))),
  ];

  const allFields: Field[] = uniqueIds
    .map((id) =>
      allFieldsWithDuplicates.find((field) => parseInt(`${field.id}`) === id)
    )
    .filter((field) => field) as Field[];

  const routineTeamIds =
    props.routine.teams.length > 0 ? props.routine.teams : [teamId.value];

  return allFields.filter((field) =>
    field.teams.find((teamId) => routineTeamIds.includes(teamId))
  );
});

function getAllowedFieldsOfType(type: string) {
  return allFieldsForRoutine.value.filter(
    (field) => field.type === type && field.teams.includes(teamId.value!)
  );
}

const auditees = computed(() => {
  const team = teamsStore.collection.find((t: Team) => t.id === teamId.value);
  if (!team) return [];
  const search = filter.value.toLocaleLowerCase();
  const auditees: Array<FullNamedUser> = [];
  clientStore.users.forEach((u: User) => {
    if (!team.users.includes(u.id)) return;
    const fullName = String(`${u.first_name} ${u.last_name}`);
    if (!fullName.toLocaleLowerCase().includes(search)) return;
    auditees.push({ ...u, fullName });
  });
  auditees.sort((a: FullNamedUser, b: FullNamedUser) => {
    return a.fullName
      .toLocaleLowerCase()
      .localeCompare(b.fullName.toLocaleLowerCase());
  });
  return auditees;
});
useModalScroller();
</script>

<template>
  <ion-content class="normal">
    <div class="ios-header-padding-top" />
    <!-- MAKE A COMPONENT to be reused in inputs modals in ticket page -->
    <div class="modal-title-bar">
      <div class="stack-row">
        <font-icon
          v-if="setting !== 'routineScope'"
          name="search"
          material
          size="1"
          class="search-icon"
          color="var(--ion-color-primary-shade)"
        />
        <ion-input
          v-if="setting !== 'routineScope'"
          class="debounce-search-input"
          :placeholder="t(`searchVerb`)"
          enterkeyhint="search"
          clearable
          v-model="filter"
        />
        <ion-button
          v-if="setting === 'routineScope'"
          fill="clear"
          class="cancel-btn"
          @click="emit('cancel')"
        >
          {{ t("cancel") }}
        </ion-button>
        <font-icon
          name="cancel"
          v-if="filter.length && setting !== 'routineScope'"
          @click="filter = ''"
          material
          outlined
          size="1"
          color="var(--f-color-discrete)"
        />
      </div>
      <ion-buttons slot="end">
        <ion-button
          v-if="setting !== 'routineScope'"
          fill="clear"
          class="cancel-btn"
          @click="emit('cancel')"
        >
          {{ t("cancel") }}
        </ion-button>
        <ion-button
          v-else
          fill="clear"
          class="cancel-btn"
          @click="validateScopeSelection"
        >
          {{ t("common.done") }}
        </ion-button>
      </ion-buttons>
    </div>
    <div
      class="modal-header"
      style="background-color: var(--ion-color-primary-contrast)"
    >
      <div class="list-title">{{ t(`routines.${setting}`) }}</div>
    </div>
    <!-- FIN MAKE A COMPONENT -->
    <ion-list ref="list" lines="none" v-if="setting === 'team'">
      <recycle-scroller class="scroller" :items="teams" :item-size="44">
        <template #default="{ item }">
          <ion-item @click="setTeam(+item.id)">
            <colored-chip
              class="clickable"
              :label="item.name"
              :emoji="item?.config?.emoji"
              :icon="item?.config?.icon"
              :color="item?.config?.color"
              :material="true"
            />
          </ion-item>
        </template>
      </recycle-scroller>
    </ion-list>
    <ion-list ref="list" lines="none" v-else-if="setting === 'routineScope'">
      <FieldValueSelector
        :selectedValues="auditee ? [auditee] : []"
        icon="person_search"
        :label="$t('auditee')"
        :allowedUsers="auditees"
        type="auditee"
        @updateSelectedValues="handleUpdateAuditee"
      />
      <FieldValueSelector
        :selectedValues="properties['zone'] || []"
        icon="location_on"
        :label="$t('zone')"
        :allowedFields="getAllowedFieldsOfType('zone')"
        type="zone"
        @updateSelectedValues="(vals) => handleUpdateProperties('zone', vals)"
      />
      <FieldValueSelector
        :selectedValues="properties['product'] || []"
        icon="inventory"
        :label="$t('product')"
        :allowedFields="getAllowedFieldsOfType('product')"
        type="product"
        @updateSelectedValues="
          (vals) => handleUpdateProperties('product', vals)
        "
      />
      <FieldValueSelector
        :selectedValues="properties['equipment'] || []"
        icon="precision_manufacturing"
        :label="$t('equipment')"
        :allowedFields="getAllowedFieldsOfType('equipment')"
        type="equipment"
        @updateSelectedValues="
          (vals) => handleUpdateProperties('equipment', vals)
        "
      />
    </ion-list>
  </ion-content>
</template>

<style scoped>
.modal-title-bar {
  padding: var(--ion-padding);
}

.list-title {
  text-transform: uppercase;
  background-color: var(--ion-background-color);
  padding: var(--ion-padding) calc(var(--ion-padding) * 2);
  font-size: var(--font-size-xs);
  color: var(--ion-color-primary-shade);
}

.user-item {
  margin-bottom: var(--ion-padding);
}

.search-icon {
  margin-left: calc(var(--ion-padding) / 2);
  margin-right: calc(var(--ion-padding) * -0.75);
}

.modal-header {
  margin-bottom: var(--ion-padding);
  border-bottom: 0;
}

.debounce-search-input {
  margin-left: calc(var(--ion-padding) / 2);
}

.stack-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
}
</style>
