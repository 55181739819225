import { ApiClient } from "./clients";
import { Schedule, ListService } from "@/types";

export const ScheduleService: ListService<Schedule> = {
  async all(options?: any): Promise<Array<Schedule>> {
    const params = {
      start_date: options?.start_date,
      end_date: options?.end_date,
      existing_routine_ids: "[]",
    };
    const routines = await ApiClient.get(
      `/api/v1/routine/instances/user/${options?.id}/`,
      params
    );
    return routines;
  },
};
