import { EventsClient } from "./clients";
import {
  FabriqEventPayload,
  SearchEventPayload,
  FabriqEvent,
  ListService,
  FabriqEventFromServer,
  EventProperty,
} from "@/types";
import { storeToRefs } from "pinia";
import { useEventsWithPropertiesStore } from "@/store/eventsWithProperties";
import { format } from "date-fns";
import { inputDateToIsoDate, isoDateToInputDate } from "@/utils/dates";

const mapPropertiesToSend = (payload: FabriqEventPayload): EventProperty[] => {
  const eventsWithPropertiesStore = useEventsWithPropertiesStore();
  const { eventTypePropertiesMap } = storeToRefs(eventsWithPropertiesStore);

  const properties = payload.properties.map((p) => {
    const property = eventTypePropertiesMap.value[p.eventPropertyTypeId];
    if (property.type === "datetime") {
      const startDate = format(new Date(p.value as string), "yyyy-MM-dd");
      const startTime = format(new Date(p.value as string), "HH:mm");
      const dateTime = inputDateToIsoDate(startDate, startTime);

      return { ...p, value: dateTime };
    }
    return { ...p };
  }) as EventProperty[];
  return properties;
};

const mapPropertiesFromResult = (result: FabriqEvent) => {
  const eventsWithPropertiesStore = useEventsWithPropertiesStore();
  const { eventTypePropertiesMap } = storeToRefs(eventsWithPropertiesStore);

  const mappedProperties = result.properties.map((p: EventProperty) => {
    const property = eventTypePropertiesMap.value[p.eventPropertyTypeId];

    if (property.type === "datetime") {
      const { startDate, startTime } = isoDateToInputDate(p.value as string);
      p.value = format(
        new Date(`${startDate}T${startTime}`),
        "yyyy-MM-dd HH:mm"
      );
    }
    return p;
  });
  result.properties = mappedProperties;
  return result;
};

export const EventsService: ListService<FabriqEvent> = {
  async add(payload: FabriqEventPayload): Promise<FabriqEvent> {
    const result = await EventsClient.post(
      `/event-type/${payload.eventTypeId}/events`,
      {
        eventTypeId: payload.eventTypeId,
        properties: mapPropertiesToSend(payload),
      }
    );
    if (!result || result.outcome === "notCreated")
      throw new Error("eventNotCreated");

    return mapPropertiesFromResult(result);
  },
  async update(payload: FabriqEventPayload): Promise<FabriqEvent> {
    try {
      const result = await EventsClient.put(`/${payload.id}`, {
        id: payload.id,
        eventTypeId: payload.eventTypeId,
        properties: mapPropertiesToSend(payload),
      });

      if (!result || result.outcome === "notUpdated") {
        throw new Error("eventNotUpdated");
      }

      return mapPropertiesFromResult(result);
    } catch (error: any) {
      console.error(error);
      throw new Error("eventNotUpdated");
    }
  },
  async remove(evt: FabriqEvent): Promise<void> {
    try {
      await EventsClient.delete(`/${evt.id}`);
    } catch (error) {
      console.error(error);
      throw new Error("eventNotDeleted");
    }
  },
  async all(
    options: SearchEventPayload
  ): Promise<{ events: FabriqEventFromServer[]; count: number | undefined }> {
    try {
      const {
        eventTypeId,
        filters,
        isComplete,
        page,
        asc,
        sortProperty,
        teamId,
      } = options;
      const params = new URLSearchParams();
      params.append("page", page?.toString() || "0");
      params.append("asc", asc ? "1" : "0");

      if (eventTypeId) {
        return EventsClient.post(
          `/event-type/${eventTypeId}/filter-events?${params.toString()}`,
          {
            filters,
            isComplete,
            ...(sortProperty ? { sortEventTypeProperty: sortProperty } : {}),
            ...(teamId ? { teamId } : {}),
          }
        );
      } else {
        return EventsClient.post(`/filter-events?${params.toString()}`, {
          filters,
          isComplete,
          ...(sortProperty ? { sortEventTypeProperty: sortProperty } : {}),
          ...(teamId ? { teamId } : {}),
        });
      }
    } catch (error) {
      console.error(error);
      throw new Error("eventNotFetched");
    }
  },
  async load(id: string): Promise<FabriqEventFromServer> {
    try {
      const result = await EventsClient.get(`/${id}`);
      return result;
    } catch (error) {
      console.error(error);
      throw new Error("eventNotFetched");
    }
  },
};
