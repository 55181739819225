<script lang="ts" setup>
import { Clipboard } from "@capacitor/clipboard";
import { computed, ComputedRef, nextTick, onMounted, ref, watch } from "vue";
import {
  alertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  modalController,
  onIonViewDidEnter,
  onIonViewWillLeave,
  toastController,
  useIonRouter,
} from "@ionic/vue";
import TaskList from "@/components/business/tickets/TaskList.vue";
import DependencyList from "@/components/business/dependencies/DependencyList.vue";
import FileList from "@/components/business/FileList.vue";
import TextInput from "@/components/ui/TextInput.vue";
import BackButton from "@/components/ui/BackButton.vue";
import AccordionItem from "@/components/ui/AccordionItem.vue";
import FieldBlock from "@/components/business/tickets/FieldBlock.vue";
import TicketOptionsModal from "@/components/modals/TicketOptionsModal.vue";
import EditTaskModal from "@/components/modals/EditTaskModal.vue";
import {
  DiamondDependency,
  Field,
  FieldType,
  Task,
  Team,
  Ticket,
  User,
} from "@/types";
import { useTicket } from "@/composables/useTicket";
import { useTicketsStore } from "@/store/tickets";
import { useTasksStore } from "@/store/tasks";
import { useTeamsStore } from "@/store/teams";
import { useDependenciesStore } from "@/store/dependencies";
import { useFieldsStore } from "@/store/fields";
import { useFabriqStore } from "@/store/fabriq";
import { useRights } from "@/composables/useRights";
import { useRoute } from "vue-router";
import { formatDistanceToNow, formatISO, parseISO } from "date-fns";
import TicketCommentsModal from "@/components/modals/TicketCommentsModal.vue";
import { getDateFnsLocales } from "@/i18n";
import { useCommentsStore } from "@/store/comments";
import { removeHtml } from "@/utils/comment-parser";
import { ticketUpdatedByMe } from "@/utils/socket";
import mixpanelTracker from "@/utils/mixpanel-tracker";
import { useTicketTemplates } from "@/composables/useTicketTemplates";
import TemplateScore from "@/components/business/tickets/TemplateScore.vue";
import { useI18n } from "vue-i18n";
import loader from "@/utils/loader";
import tracker from "@/utils/tracker";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import { useClientStore } from "@/store/client";
import { useConfigStore } from "@/store/config";
import { useCloseModal } from "@/composables/useCloseModal";
import { useAddFileItem } from "@/composables/useAddFileItem";
import { userIsAdmin } from "@/utils/user-rights";
import config from "@/config";
import { TeamSharingSettings } from "@/types/teamSharing";
import { TicketService } from "@/services/tickets";
import { useDiamondDependenciesStore } from "@/store/diamondDependencies";
import { storeToRefs } from "pinia";

const route = useRoute();
const router = useIonRouter();
const ticketStore = useTicketsStore();
const tasksStore = useTasksStore();
const fabriqStore = useFabriqStore();
const configStore = useConfigStore();
const clientStore = useClientStore();
const teamsStore = useTeamsStore();
const dependenciesStore = useDependenciesStore();
const fieldsStore = useFieldsStore();
const commentStore = useCommentsStore();
const diamondDependenciesStore = useDiamondDependenciesStore();
const editDescription = ref(false);
const editCause = ref(false);

const isAdmin = computed(() => {
  const userId = fabriqStore.user?.id;
  if (!ticket.value || !userId) return false;
  return userIsAdmin(
    ticket.value,
    allTeams.value,
    userId,
    clientStore.tmpNewTeamRoles
  );
});

const { collection: diamondDependencies } = storeToRefs(
  diamondDependenciesStore
);

tracker.next("ticket", ActionType.Process, "setup start");

const tunnel = (action: string) => {
  tracker.tunnel(
    "ticket",
    "ticket page",
    RecordCategory.Tickets,
    ActionType.Process,
    action
  );
};

const ticket: ComputedRef<Ticket> = computed(() => {
  if (!route.params?.id) return null;
  return ticketStore.collection.find((t: Ticket) => t.uuid === route.params.id);
});

const ticketComposable = useTicket(ticket);

const creator = computed(() => {
  if (!ticket.value || !ticket.value.created_by) return;
  const creator = clientStore.users.find(
    (u: User) => u.id === ticket.value.created_by
  );
  if (!creator) return null;
  if (creator?.profile?.full_name) return creator?.profile?.full_name;
  return `${creator.first_name} ${creator.last_name}`;
});
const createdSince = computed(() => {
  return formatDistanceToNow(parseISO(ticket.value.created_at), {
    locale: getDateFnsLocales(fabriqStore.locale),
  });
});

const allTeams: ComputedRef<Team[]> = computed(() => {
  if (!teamsStore.collection?.length) return [];
  return teamsStore.collection.map((t: Team) => {
    return {
      ...t,
      color: t.config?.color,
      icon: t.config?.icon,
    };
  });
});

const teams = computed(() => {
  if (!ticket.value) return [];
  if (!ticket.value.teams?.length) return [];
  const teams: Array<any> = [];
  ticket.value.teams.forEach((id: number) => {
    const team = allTeams.value.find((t: any) => t.id === id);
    if (!team) return;
    teams.push(team);
  });
  return teams;
});

watch(
  teams,
  async (value) => {
    if (!value || !ticket.value) return;
    await teamsStore.loadTeamsFromTeamSharing(ticket.value);
  },
  { immediate: true }
);

const teamSharingSettings = ref<TeamSharingSettings>({});

onMounted(async () => {
  teamSharingSettings.value = await TicketService.loadTeamSharingSettings(
    ticket.value._type || "issue"
  );
});

const { canEdit, alertCantEdit, right } = useRights(ticket, teams);

const dependencies = computed(() => {
  if (!ticket.value) return [];
  if (!dependenciesStore.collection?.length) return [];
  const dependencies: Array<any> = [];
  dependenciesStore.collection.forEach((c: any) => {
    if (c.related_id !== ticket.value.id && c.dependent_id !== ticket.value.id)
      return;
    dependencies.push(c);
  });
  return dependencies;
});

const fields = computed(() => {
  if (!ticket.value) return [];
  if (!fieldsStore.collection?.length) return [];
  const fields: Array<any> = [];
  fieldsStore.collection.forEach((e: any) => {
    if (e.ticketId !== ticket.value.id && e.ticketId !== ticket.value.uuid)
      return;
    fields.push(e);
  });
  return fields;
});

const teamsFields = computed(() => {
  const extraFieldsOfTicketById: Map<string, Field> = new Map();
  const locale = fabriqStore.locale;

  // Extra fields are either linked to a group, or to the client itself.
  // In order to get the extra fields of a ticket, we must get the extra fields
  // of any of its teams, which are the sum of the extra fields of their groups
  // and the extra fields of the client.
  const idsOfGroupsOfAnyTeamInTheTicket = new Set(
    teams.value.flatMap((team: Team) => {
      return team.groups;
    })
  );
  for (const group of clientStore.groups) {
    if (!idsOfGroupsOfAnyTeamInTheTicket.has(group.id)) {
      continue;
    }
    for (const extraField of group.extra_fields) {
      extraFieldsOfTicketById.set(extraField.id, extraField);
    }
  }
  for (const extraField of clientStore.extra_fields) {
    extraFieldsOfTicketById.set(extraField.id, extraField);
  }

  return Array.from(extraFieldsOfTicketById.values()).map((v: any) => ({
    ...v,
    name: v?.config?.i18n?.[locale] || v.name,
  }));
});

const getFieldValue = (key: string, multiple = false) => {
  if (!fields.value) return multiple ? [] : null;
  if (multiple) {
    return fields.value.filter((e) => e.type === key).map((e) => e.fieldId);
  }
  const value = fields.value.find((e) => e.type === key);
  return value ? value.fieldId : null;
};

const ticketFields = computed(() => {
  if (!ticket.value || !fields.value) return [];
  const ticketFields: Array<any> = [];
  if (!fieldIsForbidden("owner")) {
    ticketFields.push({
      key: "owner",
      multiple: false,
      readonly: !canEdit.value,
      value: {
        owner: ticketComposable.owner.value,
        ownerFullName: ticketComposable.ownerFullName.value,
      },
    });
  }
  if (!fieldIsForbidden("due_date")) {
    ticketFields.push({
      key: "due_date",
      readonly: !canEdit.value,
      multiple: false,
      value: ticket.value?.due_date,
      end: ticket.value?.end_date,
    });
  }
  if (!fieldIsForbidden("teams")) {
    ticketFields.push({
      key: "teams",
      readonly: !canEdit.value,
      multiple: true,
      coloredIconOnly: false,
      value: ticket.value?.teams,
    });
  }
  if (!fieldIsForbidden("categories")) {
    ticketFields.push({
      key: "categories",
      multiple: false,
      readonly: !canEdit.value || fieldIsReadOnly("categories"),
      coloredIconOnly: false,
      value: ticket.value?.categories?.length
        ? ticket.value.categories[0]
        : null,
    });
  }
  if (
    !hideField(FieldType.Priority) &&
    !fieldIsForbidden(FieldType.Priority, "field")
  ) {
    ticketFields.push({
      key: FieldType.Priority,
      multiple: false,
      readonly: right.value === "auditor",
      coloredIconOnly: true,
      value: getFieldValue(FieldType.Priority, false),
    });
  }
  if (!fieldIsForbidden("status")) {
    ticketFields.push({
      key: "status",
      readonly: !canEdit.value || fieldIsReadOnly("status"),
      multiple: false,
      coloredIconOnly: false,
      value: ticket.value?.status,
    });
  }
  if (
    !hideField(FieldType.Zone) &&
    !fieldIsForbidden(FieldType.Zone, "field")
  ) {
    ticketFields.push({
      key: FieldType.Zone,
      readonly: right.value === "auditor" || fieldIsReadOnly("zone", "field"),
      multiple: true,
      coloredIconOnly: true,
      value: getFieldValue(FieldType.Zone, true),
    });
  }
  if (
    !hideField(FieldType.Equipment) &&
    !fieldIsForbidden(FieldType.Equipment, "field")
  ) {
    ticketFields.push({
      key: FieldType.Equipment,
      readonly:
        right.value === "auditor" || fieldIsReadOnly("equipment", "field"),
      multiple: true,
      coloredIconOnly: true,
      value: getFieldValue(FieldType.Equipment, true),
    });
  }
  if (
    !hideField(FieldType.Product) &&
    !fieldIsForbidden(FieldType.Product, "field")
  ) {
    ticketFields.push({
      key: FieldType.Product,
      readonly: right.value === "auditor",
      multiple: true,
      coloredIconOnly: true,
      value: getFieldValue(FieldType.Product, true),
    });
  }
  if (!fieldIsForbidden("labels")) {
    ticketFields.push({
      key: "labels",
      readonly: right.value === "auditor" || fieldIsReadOnly("labels"),
      multiple: true,
      coloredIconOnly: false,
      value: ticket.value?.labels,
    });
  }
  if (
    !hideField(FieldType.Custom1) &&
    !fieldIsForbidden(FieldType.Custom1, "field")
  ) {
    const custom1 = clientStore.custom_fields["custom_1"] || {
      multiple: false,
    };
    ticketFields.push({
      key: FieldType.Custom1,
      readonly:
        right.value === "auditor" || fieldIsReadOnly("custom_1", "field"),
      multiple: custom1.multiple,
      coloredIconOnly: true,
      value: getFieldValue(FieldType.Custom1, custom1.multiple),
    });
  }
  if (
    !hideField(FieldType.Custom2) &&
    !fieldIsForbidden(FieldType.Custom2, "field")
  ) {
    const custom2 = clientStore.custom_fields["custom_2"] || {
      multiple: false,
    };
    ticketFields.push({
      key: FieldType.Custom2,
      readonly:
        right.value === "auditor" || fieldIsReadOnly("custom_2", "field"),
      multiple: custom2.multiple,
      coloredIconOnly: true,
      value: getFieldValue(FieldType.Custom2, custom2.multiple),
    });
  }
  return ticketFields;
});

const {
  ticketTemplate,
  templateFields,
  requiredFields,
  showNudges,
  loadTicketTemplate,
  nbRequiredFields,
  nbRequiredFieldsFilled,
  hideDependencies,
  hideTasks,
  hideComments,
  hideFiles,
  hideProcesses,
  fieldIsNudge,
  fieldIsForbidden,
  fieldIsReadOnly,
  getFieldPlaceholder,
  getFieldLabel,
  nudgeIsShown,
} = useTicketTemplates(ticket, ticketFields, ticketComposable.ticketFiles);

const hideField = (key: string) => {
  const fields = teamsFields.value.filter((k: any) => {
    if (k.type !== key) return false;
    if (k.teams.length === 0) return false;
    return true;
  });
  return fields.length === 0;
};

const fieldsUpdate = async (field: any, value: any) => {
  switch (field) {
    case "status":
      await ticketStore.update(ticket.value.uuid, {
        status: value?.length ? value[0] : null,
      });
      break;
    case "due_date":
      if (!value || typeof value === "string") {
        await ticketStore.update(ticket.value.uuid, { due_date: value });
      } else {
        await ticketStore.update(ticket.value.uuid, {
          due_date: value?.start,
          end_date: value?.end,
        });
      }
      break;
    case "owner":
    case "teams":
    case "categories":
    case "labels":
      await ticketStore.update(ticket.value.uuid, { [field]: value });
      break;
    default: {
      const ticketId = ticket.value.id || ticket.value.uuid;
      const values = fieldsStore.collection.filter((e: any) => {
        return e.ticketId === ticketId && field === e.type;
      });
      values.forEach((v: any) => {
        if (!value.includes(v.fieldId)) {
          fieldsStore.remove(v);
          tunnel(`remove | ${field}`);
        }
      });
      value.forEach((v: number) => {
        const idx = values.findIndex((va: any) => va.fieldId === v);
        if (idx >= 0) return;
        tunnel(`add | ${field}`);
        mixpanelTracker.track(`add | ${field} | ticket`);
        fieldsStore.add({
          ticketId,
          fieldId: v,
          type: field,
          created_at: formatISO(new Date()),
          updated_at: formatISO(new Date()),
        });
      });
    }
  }
};

const { closeModal } = useCloseModal();

const openTicketOptions = async () => {
  const modal = await modalController.create({
    component: TicketOptionsModal,
    canDismiss: true,
    mode: "ios",
    breakpoints: [0, 0.3, 0.5],
    initialBreakpoint: 0.3,
    componentProps: {
      ticket: ticket.value,
      canEdit: canEdit.value,
      onDone: (action: string) => {
        switch (action) {
          case "copy":
            tunnel("copy url");
            mixpanelTracker.track("copy | url ticket | modal");
            const webAppUrl = fabriqStore.subdomain || config.webappUrl;
            Clipboard.write({
              string: `${webAppUrl}/?ticket=${ticket.value.id}`,
            });
            break;
          case "archive":
            tunnel("archive");
            mixpanelTracker.track("archive | ticket | modal");
            ticketStore.setFromPush(true);
            ticketComposable.ticketUpdate("is_open", false, true);
            router.back();
            break;
          case "unarchive":
            tunnel("unarchive");
            ticketStore.setFromPush(true);
            ticketComposable.ticketUpdate("is_open", true, true);
            break;
          case "delete":
            tunnel("delete");
            ticketStore.setFromPush(true);
            mixpanelTracker.track("delete | ticket | modal");
            if (ticket.value?.id) {
              configStore.removeOpenedTicket(+ticket.value.id);
              diamondDependenciesStore.removeTicketEventDependencies(
                +ticket.value.id
              );
            }
            ticketStore.remove(ticket.value);
            ticketStore.save();
            if (ticket.value?.id) ticketUpdatedByMe(+ticket.value.id);
            router.back();
            break;
        }
        modal.dismiss();
      },
      onCancel: () => modal.dismiss(),
    },
  });
  tunnel("open settings");
  mixpanelTracker.track("open | ticket setting | ticket");
  await modal.present();
  await modal.onDidDismiss();
};

const { addFileItem } = useAddFileItem(
  tunnel,
  ticketComposable.addFile,
  "add | file | ticket"
);

const editTask = async (task: any) => {
  const modal = await modalController.create({
    component: EditTaskModal,
    canDismiss: true,
    mode: "ios",
    keyboardClose: false,
    breakpoints: [0, 0.5, 0.8],
    initialBreakpoint: 0.5,
    componentProps: {
      task,
      teams: teams.value,
      users: ticketComposable.users.value,
      onDone: async (updatedTask: any) => {
        closeModal(modal);
        if (!updatedTask) return;
        if (!task.id && !task.uuid) {
          tunnel("add task");
          mixpanelTracker.track("add | task | ticket");
          await tasksStore.add({
            description: updatedTask.description,
            ticket: ticket.value.id || ticket.value.uuid,
            user: updatedTask.user,
            due_date: updatedTask.due_date,
            order: ticketComposable.ticketTasks.value.length,
            updated_at: formatISO(new Date()),
            created_at: formatISO(new Date()),
          });
        } else {
          if (task.description !== updatedTask.description) {
            tunnel("update task");
            await tasksStore.update(task.uuid, {
              description: updatedTask.description,
            });
          }
          if (task.user !== updatedTask.user) {
            tunnel("update task");
            await tasksStore.update(task.uuid, { user: updatedTask.user });
          }
          if (task.due_date !== updatedTask.due_date) {
            tunnel("update task");
            await tasksStore.update(task.uuid, {
              due_date: updatedTask.due_date,
            });
          }
        }
        tasksStore.save();
      },
      onCancel: () => closeModal(modal),
    },
  });
  await modal.present();
  const content: HTMLElement | null = modal.querySelector(
    ".task-title .text-editor-content"
  );
  if (!content) return;
  content.focus();
};

const deleteTask = async (task: Task) => {
  if (!canEdit.value) return;
  await tasksStore.remove(task);
  tasksStore.save();
};

const showComments = async () => {
  tracker.begin(
    "openComments",
    "Open ticket comments",
    RecordCategory.Tickets,
    ActionType.Ui,
    "click"
  );
  commentStore.all({ ticket: ticket.value.id });
  const modal = await modalController.create({
    component: TicketCommentsModal,
    canDismiss: true,
    mode: "ios",
    breakpoints: [0, 1],
    initialBreakpoint: 1,
    componentProps: {
      users: ticketComposable.users.value,
      teams: teams.value,
      ticket: ticket.value,
      onDone: () => {
        closeModal(modal);
        commentStore.save();
      },
      onCancel: () => {
        closeModal(modal);
        commentStore.save();
      },
    },
  });
  tunnel("open comments");
  mixpanelTracker.track("open | comment | ticket");
  await modal.present();
};

const onlyWeb = async () => {
  const alert = await toastController.create({
    message: ticketComposable.t("common.onlyWeb"),
    duration: 2000,
  });
  await alert.present();
};

const nbComments = computed(() => {
  if (!ticket.value?.id && !ticket.value?.uuid) return 0;
  const coms = commentStore.collection.filter(
    (c: any) => c.ticket === ticket.value.id || c.ticket === ticket.value.uuid
  );
  return coms.length || ticket.value.comments_count;
});

onIonViewDidEnter(() => {
  loader.hide();
  loadTicketTemplate();
  const { id, title } = ticket.value;
  if (id) configStore.addOpenedTicket(+id, title);
  tunnel("open");
  tracker.end("push");
  tracker.end("notification");
  tracker.end("ticket");
  if (ticketStore.openComments) {
    ticketStore.setOpenComments(false);
    showComments();
  }
  ticketStore.setFromPush(false);
  if (title.length === 0) {
    const titleEditor: HTMLElement | null = document.querySelector(
      ".ticket-title .text-editor-content"
    );
    if (titleEditor) titleEditor.focus();
  }
});

watch(
  ticket,
  (v) => {
    if (!clientStore.config?.useEvents) return;
    if (!v) return;
    diamondDependenciesStore.all({
      entityType: "ticket",
      entityId: v.id,
    });
  },
  { immediate: true }
);

const eventDependencies = computed(() => {
  if (!clientStore.config?.useEvents) return [];
  if (!ticket.value) return [];
  return diamondDependencies.value.filter(
    (d: DiamondDependency) =>
      (d.dependentType === "event" && d.relatedId === ticket.value.id) ||
      (d.relatedType === "event" && d.dependentId === ticket.value.id)
  );
});

watch(editDescription, (value) => {
  if (value) {
    nextTick(() => {
      const editor: HTMLElement | null = document.querySelector(
        ".description-editor .text-editor-content"
      );
      if (editor) editor.focus();
    });
  }
});

watch(editCause, (value) => {
  if (value) {
    nextTick(() => {
      const editor: HTMLElement | null = document.querySelector(
        ".cause-editor .text-editor-content"
      );
      if (editor) editor.focus();
    });
  }
});

onIonViewWillLeave(() => {
  setTimeout(() => {
    ticketStore.save();
    tasksStore.save();
  }, 10);
});

const updateTicketTitle = (title: string) => {
  ticketComposable.ticketUpdate(
    "title",
    String(removeHtml(title || "")).replaceAll(/&nbsp;/g, " ")
  );
};

const tasksAccordionTitle = computed(() => {
  const nb = ticketComposable.ticketTasks.value.length;
  if (!nb) return ticketComposable.t("tickets.tasks");
  const closed = ticketComposable.ticketTasks.value.filter(
    (ta: any) => ta.done
  ).length;
  return `${ticketComposable.t("tickets.tasks")} ${closed}/${nb}`;
});

const closeTicket = async (ev: CustomEvent) => {
  if (
    ticketTemplate.value?.alertOnNudge &&
    nbRequiredFields.value > nbRequiredFieldsFilled.value
  ) {
    ev.preventDefault();
    ev.stopPropagation();
    const missing = requiredFields.value
      .filter((f) => f.nudge)
      .reduce((acc, f) => (acc.length ? `${acc}, ${f.name}` : f.name), "");
    showNudges();
    const alert = await alertController.create({
      cssClass: "alert-modal",
      header: ticketComposable.t(
        "ticketModal.confirmMissingFieldsTitle",
        nbRequiredFields.value - nbRequiredFieldsFilled.value
      ),
      message: ticketComposable.t("ticketModal.confirmMissingFields", {
        missing,
      }),
      buttons: [
        { text: ticketComposable.t("close"), role: "close" },
        {
          text: ticketComposable
            .t("ticketModal.complete")
            .replace(/^([^\s]*)\s.*$/, "$1"),
          role: "ok",
          cssClass: "alert-modal-ok",
        },
      ],
    });
    alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === "ok") return;
  }
  commentStore.clearDraft();
  router.canGoBack() ? router.back() : router.push("/main/tickets");
  tunnel("close");
  mixpanelTracker.track("close | ticket | ticket");
};

const alertRights = (alert: boolean) => {
  if (!alert) return;
  alertCantEdit();
};
const { t } = useI18n();

const { ticketFiles, ticketTasks, ticketUpdate, taskUpdate, users } =
  ticketComposable;

tracker.next("ticket", ActionType.Ui, "setup end");
</script>

<template>
  <ion-page>
    <ion-header mode="ios">
      <ion-toolbar class="ion-padding-start transparent-toolbar">
        <back-button slot="start" @back="closeTicket" />
        <ion-buttons slot="end">
          <template-score
            v-if="nbRequiredFields"
            :nbFilled="nbRequiredFieldsFilled"
            :nbTotal="nbRequiredFields"
            :template="ticketTemplate"
            :requiredFields="requiredFields"
            :ticket="ticket"
            @show="showNudges"
          />
          <ion-button
            class="toolbar-end-button"
            @click="showComments"
            v-if="!hideComments"
          >
            <font-icon
              size="1.25"
              name="chat_bubble_outline"
              material
              color="var(ion-color-primary)"
            />
            <small v-if="nbComments">&nbsp;{{ nbComments }}</small>
          </ion-button>
          <ion-button class="toolbar-end-button" @click="openTicketOptions">
            <font-icon name="more_horiz" size="1.25" material color="primary" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" class="ticket-page">
      <div v-if="ticket" class="ticket-content">
        <!-- TICKET TITLE -->
        <text-input
          v-if="!fieldIsForbidden('title')"
          :readonly="!canEdit"
          @click="alertRights(!canEdit)"
          class="ion-padding-start ion-padding-end ticket-title"
          :class="{ 'nudge-inside': fieldIsNudge('title') }"
          :placeholder="
            getFieldPlaceholder(t('ticketContent.titlePlaceholder'), 'title')
          "
          :modelValue="ticket.title"
          @update:modelValue="updateTicketTitle"
        />

        <!-- TICKET OWNER -->
        <div class="ticket-owner">{{ createdSince }} ∙ {{ creator }}</div>

        <!-- TICKET DETAILS -->
        <accordion-item
          class="no-action"
          :label="t('tickets.details')"
          v-if="ticketFields.length"
        >
          <div class="ion-padding-horizontal">
            <field-block
              v-for="field of ticketFields"
              :key="field.key"
              :field="field"
              :fields="fields"
              @click="alertRights(field.readonly)"
              :is-admin="isAdmin"
              :teams="teams"
              :team-sharing-settings="teamSharingSettings"
              :teamsFields="teamsFields"
              :templateFields="templateFields"
              :nudgeIsShown="nudgeIsShown"
              :allTeams="allTeams"
              @update="fieldsUpdate(field.key, $event)"
            />
            <div class="field" v-if="!fieldIsForbidden('description')">
              <div class="field-label">
                <font-icon
                  name="reorder"
                  size="0.875"
                  color="var(--ion-color-primary-shade)"
                  material
                />
                <div>
                  {{ getFieldLabel(t("tickets.description"), "description") }}
                </div>
              </div>
              <div
                class="field-values"
                @click="alertRights(!canEdit)"
                v-if="!ticket.description?.length && !editDescription"
              >
                <div
                  class="field-empty"
                  :class="{ nudge: fieldIsNudge('description') }"
                  @click="editDescription = true"
                >
                  {{ t("empty") }}
                </div>
              </div>
            </div>
            <text-input
              v-if="
                (!fieldIsForbidden('description') &&
                  ticket.description?.length) ||
                editDescription
              "
              class="description-editor ion-margin-bottom"
              :placeholder="
                getFieldPlaceholder(
                  t('ticketContent.descriptionPlaceholder'),
                  'description'
                )
              "
              :modelValue="ticket.description"
              :readonly="!canEdit"
              @click="alertRights(!canEdit)"
              @update:modelValue="ticketUpdate('description', $event)"
            >
              {{ ticket.description }}
            </text-input>
            <div class="field" v-if="!fieldIsForbidden('cause')">
              <div class="field-label">
                <font-icon
                  name="compare_arrows"
                  size="0.875"
                  color="var(--ion-color-primary-shade)"
                  material
                />
                <div>{{ getFieldLabel(t("tickets.cause"), "cause") }}</div>
              </div>
              <div
                class="field-values"
                @click="alertRights(!canEdit)"
                v-if="!ticket.cause?.length && !editCause"
              >
                <div
                  class="field-empty"
                  :class="{ nudge: fieldIsNudge('cause') }"
                  @click="editCause = true"
                >
                  {{ t("empty") }}
                </div>
              </div>
            </div>
            <text-input
              v-if="
                (!fieldIsForbidden('cause') && ticket.cause?.length) ||
                editCause
              "
              class="ion-margin-bottom cause-editor"
              :placeholder="
                getFieldPlaceholder(
                  t('ticketContent.rootCausePlaceholder'),
                  'cause'
                )
              "
              :modelValue="ticket.cause"
              :readonly="!canEdit"
              @click="alertRights(!canEdit)"
              @update:modelValue="ticketUpdate('cause', $event)"
            >
              {{ ticket.cause }}
            </text-input>
          </div>

          <template v-if="!hideProcesses">
            <div
              class="ticket-process"
              v-for="(process, index) of ticket.ticket_processes"
              :key="index"
              @click="onlyWeb"
            >
              <font-icon
                name="timeline"
                material
                size="1.2"
                color="var(--ion-color-secondary)"
              />
              <div>
                {{ process.name }}
              </div>
            </div>
          </template>
        </accordion-item>

        <!-- TICKET TASKS -->
        <accordion-item :label="tasksAccordionTitle" v-if="!hideTasks">
          <task-list
            class="ion-margin-vertical task-in-ticket"
            :tasks="ticketTasks"
            :users="users"
            @click="alertRights(right === 'auditor')"
            :canEdit="right !== 'auditor'"
            :right="right"
            :ticketId="ticket.id || ticket.uuid"
            @update="taskUpdate"
            @edit="editTask"
            @delete="deleteTask"
          />
          <div
            class="ticket-add-task"
            v-if="right !== 'auditor'"
            :class="{ 'no-task': !ticketTasks?.length }"
            @click="editTask({ ticket: ticket.id })"
          >
            <font-icon
              name="add"
              size="0.875"
              material
              color="var(--ion-color-secondary)"
            />
            {{ t("tickets.newTask") }}
          </div>
        </accordion-item>

        <!-- TICKET DEPENDENCIES -->
        <accordion-item
          class="no-action"
          :label="t('tickets.dependencies')"
          v-if="
            !hideDependencies &&
            ((dependencies && dependencies.length) ||
              (eventDependencies && eventDependencies.length))
          "
        >
          <dependency-list
            :entityId="ticket.id"
            :dependencies="dependencies"
            :eventDependencies="eventDependencies"
            :readonly="true"
          />
        </accordion-item>

        <!-- TICKET FILES -->
        <accordion-item :label="t('tickets.files')" v-if="!hideFiles">
          <file-list
            class="file-list-ticket"
            :viewer="true"
            :files="ticketFiles"
            v-if="ticketFiles && ticketFiles.length"
          />
          <div
            class="ticket-add-file"
            v-if="right !== 'auditor'"
            :class="{
              'no-file': !ticketFiles?.length,
              nudge: fieldIsNudge('files'),
            }"
            @click="addFileItem()"
          >
            <font-icon
              name="add"
              size="0.875"
              material
              color="var(--ion-color-secondary)"
            />
            {{ t("tickets.newFile") }}
          </div>
        </accordion-item>
        <div class="padding-for-floating">&nbsp;</div>
      </div>
    </ion-content>
  </ion-page>
</template>

<style>
.nudge {
  /* outline: 1px solid var(--ion-color-secondary); */
  box-shadow: 0 0 0 1px var(--ion-color-secondary);
  outline: none;
  transition: 0.1s;
}

.nudge-inside .text-editor {
  border-radius: var(--f-border-radius);
  /* outline: 1px solid var(--ion-color-secondary); */
  box-shadow: 0 0 0 1px var(--ion-color-secondary);
  padding: 0 calc(var(--ion-padding) / 2);
}
</style>
<style scoped>
.ticket-page {
  --background: transparent;
}

.ticket-page .ticket-content {
  background: var(--ion-color-primary-contrast);
  border-radius: var(--ion-padding);
  padding-top: calc(var(--ion-padding) * 1);
  min-height: 100%;
}

.ticket-page .ticket-content .margin-for-floating {
  background-color: var(--ion-color-primary-contrast);
}

.ticket-title {
  padding-left: calc(var(--ion-padding) / 2);
  margin-inline: calc(var(--ion-padding) / 2);
  margin-bottom: calc(var(--ion-padding) * 2);
  --text-editor-font-size: var(--font-size-xl);
  font-weight: bold;
}

.due-date.empty,
.user-avatar-input.empty {
  border: 1px solid var(--ion-color-primary-shade);
  border-radius: 50px;
  padding-left: 9px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: var(--font-size-m);
  padding-right: var(--ion-padding);
}

.due-date.empty {
  padding-top: 7px;
  padding-bottom: 7px;
}

.user-avatar-input.empty .avatar-name {
  color: var(--ion-color-primary-shade);
}

.ticket-process {
  display: flex;
  flex-direction: row;
  gap: var(--ion-padding);
  align-items: center;
  margin: var(--ion-padding);
  border: 1px solid var(--ion-border-color);
  padding: var(--ion-padding);
  border-radius: var(--f-border-radius);
}

.ticket-owner {
  color: var(--ion-color-primary-shade);
  font-size: var(--font-size-xs);
  padding: 0 var(--ion-padding);
  margin-bottom: var(--ion-padding);
}

.ticket-add-task,
.ticket-add-file {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  gap: var(--ion-padding);
  color: var(--ion-color-secondary);
  font-size: var(--font-size-s);
  padding: calc(var(--ion-padding) / 2);
  margin: var(--ion-padding);
  border-radius: var(--f-border-radius);
}

.ticket-add-task.no-task,
.ticket-add-file.no-file {
  margin-top: calc(var(--ion-padding) / 2);
}

.file-list-ticket {
  padding-top: 0;
  /* calc(var(--ion-padding) / 2); */
  padding-bottom: 0;
}
</style>
