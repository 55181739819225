import { useClientStore } from "@/store/client";
import { ApiClient } from "@/services/clients";
import { useFabriqStore } from "@/store/fabriq";
import { useTeamsStore } from "@/store/teams";
import { useTicketsStore } from "@/store/tickets";
import { useTasksStore } from "@/store/tasks";
import { useFilesStore } from "@/store/files";
import { useCommentsStore } from "@/store/comments";
import { useNotificationsStore } from "@/store/notifications";
import {
  buildMessagingSocket,
  buildReconnectingWebSocket,
} from "./socket-builder";

let teams = false;
let client: number | null = null;
let user: number | null = null;

const RELOAD_TIMER = 5000;
const UPDATED_TIMER = 20000;
const clientEvents = [
  "teamChanged",
  "clientChanged",
  "groupChanged",
  "categoryChanged",
  "labelChanged",
  "useChanged",
];
let clientEventsTimer: NodeJS.Timer | null = null;
const ticketEventsTimers: Map<number, NodeJS.Timer> = new Map();
const ticketUpdatedTimers: Map<number, NodeJS.Timer> = new Map();

const doReloadTicket = (id: number): NodeJS.Timer => {
  return setTimeout(() => {
    const ticketsStore = useTicketsStore();
    ticketsStore.load(id);
    ticketEventsTimers.delete(id);
  }, RELOAD_TIMER);
};

const doTicketUpdated = (id: number): NodeJS.Timer => {
  return setTimeout(() => {
    ticketUpdatedTimers.delete(id);
  }, UPDATED_TIMER);
};

export const ticketUpdatedByMe = (id: number) => {
  if (ticketUpdatedTimers.has(id)) {
    const timer = ticketUpdatedTimers.get(id);
    if (timer) clearTimeout(timer);
  }
  ticketUpdatedTimers.set(id, doTicketUpdated(id));
};

const doNotificationChanged = (id: number): void => {
  const notificationsStore = useNotificationsStore();
  try {
    notificationsStore.load(id);
  } catch (e) {}
};

const reloadTicket = (id: number) => {
  if (ticketUpdatedTimers.has(id)) return;
  if (ticketEventsTimers.has(id)) {
    const timer = ticketEventsTimers.get(id);
    if (timer) clearTimeout(timer);
  }
  ticketEventsTimers.set(id, doReloadTicket(id));
};

export default (url: string) => {
  const socket = buildMessagingSocket(buildReconnectingWebSocket(url));

  socket.onopen = async () => {
    const token = await ApiClient.getFreshToken();
    const clientStore = useClientStore();
    const fabriqStore = useFabriqStore();
    const teamsStore = useTeamsStore();
    if (!user && fabriqStore.user?.id) {
      user = fabriqStore.user?.id;
      socket.emit("userSubscribe", { userId: user, token: token?.accessToken });
    }
    if (!client && clientStore.client?.id) {
      client = clientStore.client?.id;
      if (!client) return;
      socket.emit("clientSubscribe", {
        clientId: client,
        token: token?.accessToken,
      });
    }
    if (!teams) {
      teams = true;
      const teamIds = teamsStore.collection.map((t: any) => t.id);
      fabriqStore.setTeams(teamIds);
    }
  };

  socket.onclose = () => {
    user = null;
    client = null;
    teams = false;
  };

  socket.onmessage = (ev: MessageEvent) => {
    try {
      const data = JSON.parse(ev.data);
      const eventName = data.event;
      const params = data.args;

      if (eventName.includes("Changed")) {
        switch (eventName) {
          case "ticketChanged": {
            const fabriqStore = useFabriqStore();
            if (fabriqStore.user?.id === params[1]) return;
            return reloadTicket(params[0]);
          }
          case "commentChanged":
            if (params.length < 3 || params[1] !== "ticket") return;
            return reloadTicket(params[2]);
          case "taskChanged":
          case "ticketfileChanged":
            if (params.length < 2) return;
            return reloadTicket(params[1]);
          case "notificationChanged": {
            doNotificationChanged(params[0]);
          }
        }
        if (clientEvents.includes(eventName)) {
          if (clientEventsTimer) clearTimeout(clientEventsTimer);
          clientEventsTimer = setTimeout(() => {
            const fabriqStore = useFabriqStore();
            const teamsStore = useTeamsStore();
            fabriqStore.loadUserData(teamsStore);
          }, RELOAD_TIMER);
        }
      } else if (eventName.includes("Delete")) {
        switch (eventName) {
          case "ticketDeleted": {
            const ticketsStore = useTicketsStore();
            const ticket = ticketsStore.collection.find(
              (t: any) => t.id === params[0]
            );
            if (!ticket) return;
            return ticketsStore.remove(ticket);
          }
          case "taskDeleted": {
            const tasksStore = useTasksStore();
            const task = tasksStore.collection.find(
              (t: any) => t.id === params[0]
            );
            if (!task) return;
            return tasksStore.remove(task);
          }
          case "commentDeleted": {
            const commentsStore = useCommentsStore();
            const comment = commentsStore.collection.find(
              (t: any) => t.id === params[0]
            );
            if (!comment) return;
            return commentsStore.remove(comment);
          }
          case "ticketfileDeleted": {
            const filesStore = useFilesStore();
            const file = filesStore.collection.find(
              (f: any) => f.id === params[0]
            );
            if (!file) return;
            return filesStore.remove(file);
          }
        }
        if (clientEvents.includes(eventName)) {
          if (clientEventsTimer) clearTimeout(clientEventsTimer);
          clientEventsTimer = setTimeout(() => {
            const fabriqStore = useFabriqStore();
            const teamsStore = useTeamsStore();
            fabriqStore.loadUserData(teamsStore);
          }, RELOAD_TIMER);
        }
      }
    } catch (e) {}
  };

  return socket;
};
