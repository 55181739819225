<script lang="ts" setup>
import { ApiClient } from "@/services/clients";
import {
  BarcodeScanner,
  BarcodeFormat,
} from "@capacitor-mlkit/barcode-scanning";
import {
  computed,
  ref,
  onBeforeUnmount,
  onDeactivated,
  watch,
  nextTick,
} from "vue";
import {
  toastController,
  IonInput,
  IonToolbar,
  onIonViewDidEnter,
  onIonViewDidLeave,
} from "@ionic/vue";
import RoutePage from "@/components/ui/RoutePage.vue";
import { useRoutinesStore } from "@/store/routines";
import { useInstancesStore } from "@/store/instances";
import { useTemplatesStore } from "@/store/templates";
import { useTeamsStore } from "@/store/teams";
import { useFabriqStore } from "@/store/fabriq";
import { useConfigStore } from "@/store/config";
import { storeToRefs } from "pinia";
import { useI18n } from "@/composables/useI18n";
import { useUsers } from "@/composables/useUsers";
import { useStartInstance } from "@/composables/useStartInstance";
import { format, subDays, addDays } from "date-fns";
import InstanceList from "@/components/business/routines/InstanceList.vue";
import RoutineList from "@/components/business/routines/RoutineList.vue";
import FiltersBar from "@/components/ui/FiltersBar.vue";
import { Routine, Instance, TemplateStep, FilledInstance, Team } from "@/types";
import { useExecutionsStore } from "@/store/executions";
import mixpanelTracker from "@/utils/mixpanel-tracker";
import { BarcodeScannerUI } from "@/utils/barcode-scanner-ui";
import storage from "@/utils/storage";
import tracker from "@/utils/tracker";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import LoadingOverlay from "@/components/ui/LoadingOverlay.vue";
import { startOfWeek, endOfWeek } from "date-fns";
import { CapacitorHttp as Http } from "@capacitor/core";

const tunnel = (action: string) => {
  tracker.tunnel(
    "routines",
    "routines page",
    RecordCategory.Routines,
    ActionType.Process,
    action
  );
};

const fabriqStore = useFabriqStore();
const routinesStore = useRoutinesStore();
const instancesStore = useInstancesStore();
const templatesStore = useTemplatesStore();
const teamsStore = useTeamsStore();
const configStore = useConfigStore();

const search = ref("");
let scanning = false;

const { t } = useI18n();

const hasEvLoader = ref(false);

const executionsStore = useExecutionsStore();
const { online, requests } = storeToRefs(fabriqStore);

const filter = ref("scheduled");
const filters = ["scheduled", "favorite", "routines"];

const barcodeUIBuilder = new BarcodeScannerUI();

const { loading, loadingText, startScheduledInstance, startRoutine } =
  useStartInstance();

const loadAllTeamRoutines = async () => {
  const teamIds = teamsStore.collection.map((t: any) => t.id);
  await routinesStore.loadAll(teamIds);
};

const loadAllData = async (ev?: any) => {
  fabriqStore.setPage("routines");
  const userId = fabriqStore.user?.id;
  if (!userId) return;
  const routinesLoaded = await storage.get("routinesLoaded");
  if (!ev && routinesLoaded && filter.value !== "scheduled") return;
  const today = new Date();
  const start_date = format(
    subDays(startOfWeek(today, { weekStartsOn: 1 }), 5),
    "yyyy-MM-dd"
  );
  const end_date = format(
    addDays(endOfWeek(today, { weekStartsOn: 1 }), 5),
    "yyyy-MM-dd"
  );

  const load = async () => {
    if (filter.value === "scheduled") {
      await instancesStore.all({
        start_date,
        end_date,
        id: userId,
        flush: true,
      });
    } else {
      await loadAllTeamRoutines();
    }
  };
  if (filter.value !== "scheduled") storage.set("routinesLoaded", true);
  if (ev) {
    hasEvLoader.value = true;
    nextTick(async () => {
      await load();
      ev?.target?.complete();
      hasEvLoader.value = false;
    });
  } else {
    load();
    hasEvLoader.value = false;
  }
};

const favorites = computed(() => configStore.config?.favoriteRoutines || []);

const toggleFavorite = (id: number) => {
  if (favorites.value.includes(id)) {
    tunnel("favorite remove");
    configStore.removeFavoriteRoutine(id);
  } else {
    tunnel("favorite add");
    configStore.addFavoriteRoutine(id);
  }
};

const instances = computed(() => {
  const userId = fabriqStore.user?.id;
  if (!userId) return;
  const instances: Array<FilledInstance> = [];
  instancesStore.collection.forEach((i: Instance) => {
    if (!i.id) {
      /**
       * 17/09/2024 | We are reportrting to Sentry momentarily to validate
       * a hypothesis.
       * To remove after 18/09/2024
       */
      fabriqStore.reportSentry(
        new Error("RoutinesPage computed instances: id is undefined"),
        i
      );
      instancesStore.remove(i);
      return;
    }
    if (!i?.users?.includes(userId)) return;
    const routine = routinesStore.collection.find(
      (r: Routine) => r.id === i.routine_id
    );
    if (!routine || routine.is_archived) return;
    const templateId = i.template_id || routine.template_id;
    const template = templatesStore.collection.find(
      (r: Routine) => r.id === templateId
    );
    const execution = executionsStore.collection.find(
      (e: any) => e.instance === i.id
    );
    const hasKpiStep =
      template?.steps.some(
        (step: TemplateStep) => step.step_type === "indicator"
      ) || false;
    const isDisabledOnOffline =
      !template || typeof i.id !== "number" || !execution || hasKpiStep;
    const disabled = online.value ? false : isDisabledOnOffline;
    const is_done =
      execution?.is_done || !!(i.scheduler && i.scheduler.is_done);
    const has_started = !!(i.scheduler && i.scheduler.has_started);
    instances.push({
      ...i,
      routine,
      template,
      disabled,
      is_done,
      has_started,
    });
  });
  return instances;
});

const routines = computed(() => {
  const userId = fabriqStore.user?.id;
  if (!userId) return;
  const routines: Array<any> = [];
  routinesStore.collection
    .filter((r: Routine) => {
      return teams.value
        ?.filter((team) => r.teams.includes(team.id))
        .some((team) => !team.auditors.includes(userId));
    })
    .forEach((i: Routine) => {
      routines.push({
        ...i,
        disabled: !online.value,
      });
    });
  return routines;
});

const teams = computed(() => {
  const userId = fabriqStore.user?.id;
  if (!userId) return;
  const teams = [...teamsStore.collection];
  teams.sort((a: Team, b: Team) =>
    a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())
  );
  return teams;
});

const stopScan = async () => {
  if (!scanning) return;
  scanning = false;
  document.body.style.display = "block";
  await BarcodeScanner.removeAllListeners();
  await BarcodeScanner.stopScan();
  barcodeUIBuilder.remove();
};

onIonViewDidEnter(() => loadAllData());
onIonViewDidLeave(() => (routinesLoaded = false));

let routinesLoaded = false;
const mixevts = (val: string) => {
  if (val === "scheduled") return "schedule tab";
  if (val === "favorite") return "favorites tab";
  return "all routines tab";
};
watch(filter, (val) => {
  tunnel(`tab ${val}`);
  mixpanelTracker.track(`open | ${mixevts(val)} | routine screen`);
  if (routinesLoaded) return;
  if (val === "scheduled") return;
  routinesLoaded = true;
  loadAllData();
});

watch(
  loading,
  (val) => {
    fabriqStore.$patch((state) => (state.hideTabs = !!val));
  },
  { immediate: true }
);

onDeactivated(() => {
  stopScan();
});

onBeforeUnmount(() => {
  stopScan();
});

const openBarcodeScanner = async () => {
  const status = await BarcodeScanner.checkPermissions();
  if (!status.camera) {
    const { camera } = await BarcodeScanner.requestPermissions();
    if (!camera) return false;
  }
  let loadingPromise: Promise<void> | null = null;
  const routinesLoaded = await storage.get("routinesLoaded");
  if (!routinesLoaded) {
    loadingPromise = loadAllData();
  }
  scanning = true;

  // await BarcodeScanner.hideBackground();
  try {
    document.body.style.display = "none";
    barcodeUIBuilder.make(stopScan, t("common.qrcode"));
    const listener = await BarcodeScanner.addListener(
      "barcodeScanned",
      async (result) => {
        if (result.barcode) {
          listener.remove();
          stopScan();
          const url = `${result.barcode.displayValue}?plain=true`;
          const options = ApiClient.getRequestOptions(url);
          const response = await Http.get(options);
          if (!response || !response.data) return;
          if (loadingPromise) await loadingPromise;
          const routineId = response.data.replace(
            /^.*routine=([0-9]+).*$/,
            "$1"
          );
          const routine = routinesStore.collection.find(
            (r: any) => r.id === +routineId
          );
          if (!routine) {
            const toast = await toastController.create({
              message: t("routines.waitLoading"),
              duration: 2000,
            });
            toast.present();
            return loadAllTeamRoutines();
          }
          tunnel("barcode");
          startRoutine(routine);
        }
      }
    );

    await BarcodeScanner.startScan({
      formats: [BarcodeFormat.QrCode],
    });
  } catch (e) {
    console.error(e);
  }
};

const { users } = useUsers();
</script>

<template>
  <route-page
    :title="t('routine', 2)"
    class="routines-page"
    @refresh="loadAllData"
    fabIcon="qrcode"
    @fab="openBarcodeScanner"
  >
    <!-- <route-page :title="t('routine', 2)" @refresh="loadAllData"> -->
    <template v-slot:toolbar>
      <ion-toolbar>
        <filters-bar :filters="filters" v-model="filter" />
      </ion-toolbar>
    </template>
    <div :class="{ 'ion-padding-top': filter === 'favorite' }">
      <div class="search-input" v-if="filter === 'routines' && !requests">
        <font-icon
          name="search"
          material
          size="1"
          color="var(--ion-color-primary-shade)"
        />
        <ion-input
          :placeholder="t(`common.search`)"
          enterkeyhint="search"
          v-model="search"
          @keypress.enter="$event.target.blur()"
        />
      </div>
      <instance-list
        class="margin-for-floating"
        v-if="filter === 'scheduled'"
        :hideLoader="hasEvLoader"
        :instances="instances"
        :users="users"
        @goto="startScheduledInstance"
        @reload="loadAllData"
      />
      <routine-list
        class="margin-for-floating"
        v-else
        :routines="routines"
        :teams="teams"
        :favorites="favorites"
        :routine-filter="search"
        :favorites-only="filter === 'favorite'"
        :users="users"
        :hideLoader="hasEvLoader"
        @goto="startRoutine"
        @favorite="toggleFavorite"
      />
    </div>
    <LoadingOverlay
      v-if="loading"
      :loadingText="loadingText"
      spinnerName="crescent"
    />
  </route-page>
</template>

<style scoped>
.search-input {
  margin: var(--f-padding-m) var(--ion-padding);
}
</style>
