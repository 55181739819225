import Dexie, { Dexie as DexieType } from "dexie";

import {
  saveCreated,
  saveUpdated,
  saveDeleted,
  updateEntityInStore,
  updateIdInStore,
} from "@/utils/offline-methods-idb";

import vault from "@/utils/vault";

import { toRaw } from "vue";

export enum RequestType {
  CREATED,
  UPDATED,
  DELETED,
}

export class FabriqIdbStorage {
  #idb: DexieType;
  #stores: Array<string> = [];
  #worker: Worker;

  static get #DB_NAME(): string {
    return "FabriqIDB";
  }

  static get #DEFAULT_IDB_COLUMNS(): Array<string> {
    return ["uuid", "&id", "updated_at"];
  }

  constructor(workerPath = "idb-worker.js") {
    this.#idb = new Dexie(FabriqIdbStorage.#DB_NAME);
    this.#worker = new Worker(workerPath);
  }

  addMessageListener(listener: any) {
    this.#worker.addEventListener("message", listener);
  }

  postMessage(payload: any) {
    this.#worker.postMessage(payload);
  }

  async createDb(): Promise<FabriqIdbStorage> {
    const columns = FabriqIdbStorage.#DEFAULT_IDB_COLUMNS.join(", ");
    const schemas = this.#stores.map((name: string): [string, string] => {
      return [name, columns];
    });
    const schema = {
      cachedFiles: "uuid, url, created_at",
      requests: "id++, table, type, uuid, created_at",
      performances: "id++, key, createdAt",
      storage: "key",
      ...Object.fromEntries(schemas),
    };
    const version = 8;
    this.#idb.version(version).stores(schema);
    this.postMessage({ name: "init", schema, version });
    this.#removeOld();
    await this.#idb.open();
    return this;
  }

  #removeOld() {
    try {
      const old = new Dexie("FabriqDb");
      old.delete();
    } catch (e) {
      console.log("No OLD DB", e);
    }
  }

  set(key: string, value: any): Promise<boolean> | undefined {
    return vault.set(key, value);
  }

  async get(key: string): Promise<any> {
    try {
      return vault.get(key);
    } catch (e) {
      console.warn(key, e);
    }
  }

  remove(key: string): Promise<boolean> | undefined {
    return vault.remove(key);
  }

  async createTable(name: string): Promise<any> {
    if (this.#stores.includes(name))
      throw new Error(`Table ${name} already exists`);
    this.#stores.push(name);
  }

  async getAll(name: string, last: string | undefined | null) {
    if (!last) {
      const entities = await this.#idb.table(name).toArray();
      return entities;
    } else {
      const entities = await this.#idb
        .table(name)
        .where("updated_at")
        .above(last)
        .toArray();
      return entities;
    }
  }

  async getModified(name: string) {
    const entities = await this.#idb
      .table("requests")
      .where("table")
      .equals(name)
      .filter((r: any) => (r.attempts ?? 0) < 3)
      .toArray();
    entities.sort((a: any, b: any) => a.created_at - b.created_at);
    return entities;
  }

  async performRequests(name: string, store: any, api: any, hooks: any) {
    await this.#idb.transaction("rw", this.#idb.table("requests"), async () => {
      const modifications = await this.getModified(name);
      const requests: any = [];
      const toRemove: any = [];
      modifications.forEach((e: any) => {
        if (e.type !== RequestType.DELETED) {
          const idx = requests.findIndex((r: any) => r.uuid === e.uuid);
          if (idx < 0) {
            requests.push(e);
          } else {
            requests[idx].fields = {
              ...(requests[idx].fields || {}),
              ...(e.fields || {}),
            };
            toRemove.push(e.id);
          }
        } else {
          requests.push(e);
        }
      });
      await this.#idb.table("requests").bulkPut(requests);
      await this.#idb.table("requests").bulkDelete(toRemove);
    });
    const requests = await this.getModified(name);
    for (const request of requests) {
      try {
        const entity = store.collection.find(
          (e: any) => e.uuid === request.uuid
        );
        if (request.type === RequestType.CREATED) {
          if (!entity) {
            await this.#idb.table("requests").delete(request.id);
            continue;
          }
          try {
            const added = await saveCreated(api, hooks, entity);
            await this.#idb.table(name).update(request.uuid, added);
            updateIdInStore(request.uuid, added.id, store);
            if (request.fields) {
              const updated = await saveUpdated(
                request.fields,
                api,
                hooks,
                { ...added, uuid: request.uuid },
                store
              );
              await this.#idb.table(name).update(request.uuid, updated);
            } else {
              await updateEntityInStore(request.uuid, added, store);
            }
            await this.#idb.table("requests").delete(request.id);
          } catch (e: any) {
            if (e?.message === "500")
              await this.#idb.table("requests").delete(request.id);
            else await this.#updateRequestAttemps(request, e, name, entity);
          }
        } else if (request.type === RequestType.UPDATED) {
          try {
            const updated = await saveUpdated(
              request.fields,
              api,
              hooks,
              entity,
              store
            );
            await this.#idb.table(name).update(request.uuid, updated);
            await this.#idb.table("requests").delete(request.id);
            await updateEntityInStore(request.uuid, updated, store);
          } catch (e: any) {
            if (e?.message === "500")
              await this.#idb.table("requests").delete(request.id);
            else await this.#updateRequestAttemps(request, e, name, entity);
          }
        } else if (request.type === RequestType.DELETED) {
          await saveDeleted(api, hooks, request.entity)
            .catch(() => this.#idb.table("requests").delete(request.id))
            .then(() => this.#idb.table("requests").delete(request.id));
        }
      } catch (err) {
        console.error("Requests failed !", err);
        throw err;
      }
    }
  }

  async #updateRequestAttemps(
    request: any,
    error: any,
    name: string,
    entity: any = null
  ) {
    const attempts = (request.attempts || 0) + 1;
    await this.#idb.table("requests").update(request.id, {
      ...request,
      attempts,
    });
    if (attempts >= 3) {
      if (entity) {
        await this.#idb.table(name).delete(request.uuid);
      }
      await this.#idb.table("requests").delete(request.id);
      throw error;
    }
  }

  async insertEntity(name: string, entity: any, noRequest = false) {
    await Promise.all([
      this.#idb.table(name).add(entity),
      noRequest
        ? Promise.resolve()
        : this.#idb.table("requests").add({
            table: name,
            type: RequestType.CREATED,
            uuid: entity.uuid,
            created_at: new Date(),
          }),
    ]);
    return entity;
  }

  async updateEntity(
    name: string,
    entity: any,
    updatedFields: Array<string>,
    noRequest = false
  ) {
    const columns = updatedFields.map((f: string) => [f, entity[f]]);
    const fields = Object.fromEntries(columns);
    await Promise.all([
      this.#idb.table(name).where("uuid").equals(entity.uuid).modify(fields),
      noRequest
        ? Promise.resolve()
        : this.#idb.table("requests").add({
            table: name,
            type: RequestType.UPDATED,
            uuid: entity.uuid,
            created_at: new Date(),
            fields,
          }),
    ]);
  }

  async removeEntity(name: string, entity: any, noRequest = false) {
    await Promise.all([
      this.#idb.table(name).where("uuid").equals(entity.uuid).delete(),
      noRequest
        ? Promise.resolve()
        : this.#idb.table("requests").add({
            table: name,
            type: RequestType.DELETED,
            uuid: entity.uuid,
            entity: entity,
            created_at: new Date(),
          }),
    ]);
  }

  deleteEntity(name: string, id: any) {
    return this.#idb.table(name).where("uuid").equals(id).delete();
  }

  getDb() {
    return this.#idb;
  }

  async clear() {
    const promises = this.#stores.map((name: string) => {
      return this.#idb.table(name).clear();
    });
    promises.push(this.#idb.table("requests").clear());
    promises.push(this.#idb.table("cachedFiles").clear());
    promises.push(this.#idb.table("storage").clear());
    await Promise.allSettled(promises);
    await vault.clear();
  }

  async writeToStorage(key: string, value: any) {
    return this.#idb.table("storage").put({ key, value: toRaw(value) });
  }

  async readFromStorage(key: string) {
    const item = await this.#idb.table("storage").get(key);
    return item?.value;
  }
}
