<script lang="ts" setup>
import { IonList, IonItemGroup, modalController } from "@ionic/vue";
import {
  computed,
  toRaw,
  onMounted,
  ref,
  nextTick,
  ComputedRef,
  watch,
} from "vue";
import CommentItem from "@/components/business/tickets/CommentItem.vue";
import CommentEditor from "@/components/business/tickets/CommentEditor.vue";
import CommentReactionsModal from "@/components/modals/CommentReactionsModal.vue";
import {
  User,
  Ticket,
  Task,
  Comment,
  ExecutionAnswer,
  Team,
  FabriqFile,
} from "@/types";
import { commentParser, removeHtml } from "@/utils/comment-parser";
import { Keyboard } from "@capacitor/keyboard";
import LoadingBlock from "@/components/ui/LoadingBlock.vue";
import { useCommentsStore } from "@/store/comments";
import { useFabriqStore } from "@/store/fabriq";
import { storeToRefs } from "pinia";
import { useNoOffline } from "@/composables/useNoOffline";
import { calcModalPercents } from "@/utils/modals";
import { useFilesStore } from "@/store/files";
import { v4 } from "uuid";
import { useAddFileItem } from "@/composables/useAddFileItem";
import tracker from "@/utils/tracker";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import { formatISO } from "date-fns";
import { CommentReaction } from "@/types";

interface Props {
  comments: Comment[];
  users?: User[];
  tickets?: Ticket[];
  tasks?: Task[];
  teams?: Team[];
  answers?: ExecutionAnswer[];
  start?: string | null;
}

const props = withDefaults(defineProps<Props>(), {
  comments: (): Comment[] => [],
  users: () => [],
  tickets: () => [],
  tasks: () => [],
  teams: () => [],
  answers: () => [],
  start: null,
});

interface CommentWithChildren extends Comment {
  children?: Comment[];
}

const commentsStore = useCommentsStore();
const fabriqStore = useFabriqStore();
const filesStore = useFilesStore();
const { noOffline } = useNoOffline();
let scrollToComment: Comment | null = null;
const editor = ref();

const { requests } = storeToRefs(fabriqStore);
const { draft, commentId, parentId, to, loading } = storeToRefs(commentsStore);
const sortComments = (a: Comment, b: Comment) =>
  a.created_at.localeCompare(b.created_at);

const sorted: ComputedRef<CommentWithChildren[]> = computed(() => {
  const comments = props.comments.map((c: Comment) => ({ ...c, children: [] }));
  comments.sort(sortComments);
  return comments;
});

const ordered = computed(() => {
  const comments: CommentWithChildren[] = [];
  sorted.value.forEach((c: CommentWithChildren) => {
    if (c.parent) return;
    const children = sorted.value.filter(
      (ch: CommentWithChildren) => ch.parent === c.id
    );
    children.sort(sortComments);
    comments.push({ ...c, children });
  });
  return comments;
});

const pinnedComments = computed(() => {
  return sorted.value.filter((c: CommentWithChildren) => c.pinned);
});

const userId = fabriqStore.user?.id;

const nextUuid = ref(v4());

const emit = defineEmits<{
  (
    event: "edit",
    payload: { comment: string; commentId?: number | null }
  ): void;
  (
    event: "add",
    payload: { comment: string; parentId?: number | null; uuid: string }
  ): void;
}>();

const save = (comment: string) => {
  if (commentId.value) {
    emit("edit", { comment, commentId: commentId.value });
    if (comment) filesStore.save();
  } else {
    emit("add", { comment, parentId: parentId.value, uuid: nextUuid.value });
    nextUuid.value = v4();
  }
  commentsStore.clearDraft();
};

const saveDraft = (draft: string) => {
  commentsStore.setDraft(draft);
};

const scrollToLast = () => {
  if (scrollToComment) {
    scrollToComment = null;
    return;
  }
  const last = document.querySelector(
    ".comment-list .comment-group:last-child .comment:last-child"
  );
  if (!last) return console.warn("no last comment");
  last.scrollIntoView({ behavior: "smooth", block: "end" });
};

onMounted(() => {
  Keyboard.addListener("keyboardDidShow", () => {
    setTimeout(() => scrollToLast(), 200);
  });
  if (props.start?.length) editor.value.initialize(props.start);
  if (draft.value) editor.value.initialize(draft.value);
  if (!props.start?.length && !draft.value) editor.value.clear(true);
});

const files = computed(() => {
  if (!commentId.value) return [];
  return filesStore.collection.filter(
    (f: FabriqFile) => f.comment === commentId.value
  );
});

const setEditorFromComment = (id: number, parse = true) => {
  const comment = props.comments.find((c: any) => c.id === id);
  if (!comment) return false;
  scrollToComment = comment;
  if (parse) {
    const to = removeHtml(commentParser(comment.content));
    commentsStore.setParentTo(to);
  } else {
    commentsStore.setDraft(comment.content);
  }
  editor.value.initialize(draft.value);
  return true;
};

const toggleParentId = (id: any) => {
  commentsStore.setEditedComment(null);
  if (parentId.value === id || !id) {
    commentsStore.setParentComment(null);
  } else {
    if (!setEditorFromComment(id, true)) return;
    commentsStore.setParentComment(id);
  }
};

const editComment = (id: any) => {
  commentsStore.setEditedComment(null);
  commentsStore.setParentComment(null);
  if (commentId.value === id || !id) return;
  commentsStore.setParentTo(to);
  nextTick(() => {
    if (!setEditorFromComment(id, false)) return;
    commentsStore.setEditedComment(id);
  });
};

const addFile = async (file: FabriqFile) => {
  const comment = props.comments.find((c: any) => c.id === commentId.value);
  await filesStore.add({
    ...toRaw(file),
    comment: comment?.id || nextUuid.value,
  });
};

const removeFile = async (file: FabriqFile) => {
  await filesStore.remove(file);
  filesStore.save();
};

const unreact = ({
  reaction,
  comment,
}: {
  reaction: any;
  comment: Comment;
}) => {
  if (!fabriqStore.online) return noOffline();
  scrollToComment = comment;
  commentsStore.unreact(reaction, comment);
};

const tunnel = (action: string) => {
  tracker.tunnel(
    "ticket",
    "ticket page",
    RecordCategory.Tickets,
    ActionType.Process,
    action + " | comment"
  );
};

let addFileCommentId: number | string | null | undefined;
const addFileToComment = (comment: Comment) => {
  addFileCommentId = comment.id || comment.uuid;
  addFileItem();
};

const addImage = async (image: any) => {
  const now = formatISO(new Date());
  await filesStore.add({
    ...image,
    comment: addFileCommentId,
    created_at: now,
    updated_at: now,
  });
  filesStore.save();
};

const { addFileItem } = useAddFileItem(
  tunnel,
  addImage,
  "add | file | comment"
);

const openCommentReactionsModal = async ({ comment }: { comment: Comment }) => {
  const mine = userId === comment.author;
  const breakpoints = calcModalPercents(54, mine ? 7 : 5, -50);
  breakpoints.push(0.85);
  breakpoints.sort();
  scrollToComment = comment;
  const modal = await modalController.create({
    component: CommentReactionsModal,
    canDismiss: true,
    mode: "ios",
    keyboardClose: false,
    breakpoints,
    initialBreakpoint: breakpoints[1],
    componentProps: {
      mine,
      pinned: comment.pinned,
      onBreakpoint: async () => {
        await modal.setCurrentBreakpoint(1);
      },
      onCancel: async () => {
        await modal.dismiss();
      },
      onDone: async ({
        action,
        emoji,
      }: {
        action: "emoji" | "reply" | "pin" | "file" | "delete" | "edit";
        emoji?: string;
      }) => {
        await modal.dismiss();
        switch (action) {
          case "emoji":
            if (!fabriqStore.online) return noOffline();
            const reaction = (comment.reactions || []).find(
              (r: CommentReaction) =>
                r.user === fabriqStore.user?.id && r.reaction === emoji
            );
            if (!reaction) return commentsStore.react(comment, emoji);
            return commentsStore.unreact(reaction, comment);
          case "reply":
            return toggleParentId(comment.parent || comment.id);
          case "file":
            return addFileToComment(comment);
          case "pin":
            if (!fabriqStore.online) return noOffline();
            return commentsStore.togglePin(comment);
          case "delete":
            await commentsStore.remove({
              uuid: comment.uuid,
              id: comment.id,
            });
            return commentsStore.save();
          case "edit":
            return editComment(comment.id);
        }
        scrollToComment = null;
      },
    },
  });
  modal.breakpoints = [0.3, 1];
  await modal.present();
};

const cancel = () => {
  commentsStore.clearDraft();
  editor.value.initialize("");
};

watch(loading, (v) => {
  if (!v) return;
  nextTick(() => scrollToLast());
});

watch(
  () => props.comments,
  () => {
    nextTick(() => scrollToLast());
  },
  { immediate: true }
);
</script>

<template>
  <div class="comment-list-container" ref="containerRef">
    <loading-block v-if="!ordered.length && requests" />
    <ion-list class="comment-list" lines="none">
      <comment-item
        v-for="comment of pinnedComments"
        :key="`pinned_${comment.id}`"
        :comment="comment"
        :users="users"
        @react="openCommentReactionsModal"
        @unreact="unreact"
      />
      <ion-item-group
        v-for="comment of ordered"
        :key="comment.uuid"
        class="comment-group"
        :class="{
          selected: parentId === comment.id || commentId === comment.id,
        }"
      >
        <comment-item
          :comment="comment"
          :users="users"
          @react="openCommentReactionsModal"
          @unreact="unreact"
        />
      </ion-item-group>
    </ion-list>
    <comment-editor
      ref="editor"
      :tickets="tickets"
      :tasks="tasks"
      :teams="teams"
      :files="files"
      :answers="answers"
      :users="users"
      :start="start"
      :reply="!!parentId"
      :edit="!!commentId"
      :to="to"
      @addFile="addFile"
      @removeFile="removeFile"
      @save="save"
      @draft="saveDraft"
      @cancel="cancel"
    />
  </div>
</template>

<style scoped>
.comment-list-container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: var(--ion-safe-area-bottom);
  z-index: -1;
  border-top: 1px solid var(--ion-border-color);
}

.comment-list,
.routines-empty {
  flex: 1;
  z-index: 1;
  max-height: calc(100% - 48px);
  overflow-y: auto;
}
</style>
<style>
.comment-group.selected {
  --ion-item-background: var(--ion-background-color);
}

.comment-group .comment-avatar {
  position: relative;
  z-index: 1;
  margin-right: 6px;
  height: 100%;
}

.comment-group .comment-container {
  position: relative;
}

.comment-group .comment-container::before {
  content: " ";
  position: absolute;
  background-color: var(--ion-border-color);
  left: 12px;
  top: 0;
  bottom: 0;
  width: 1px;
  z-index: -1;
}

.comment-group .comment:first-child .comment-container::before {
  top: 15px;
}

.comment-group .comment:last-child .comment-container::before {
  bottom: calc(100% - 15px);
}
</style>
