import { computed, Ref } from "vue";
import { Template, TemplateStep } from "@/types";
export const useInstancePage = (template: Ref<Template>) => {
  const optionalSteps = computed(() => {
    const { steps } = template.value;
    if (!steps || !steps.length) return [];
    return (
      steps
        .map((s: TemplateStep) => {
          if (s.is_optional) return s.id;
        })
        //  using this to get rid of undefined values in step id
        .filter((el) => el)
    );
  });

  return {
    optionalSteps,
  };
};
